import React from "react";
// import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import styled from "styled-components";
import "./Rating.css";
import axios from "axios";
import { useEffect } from "react";

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})``;

// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

// function Items({ currentItems }) {
//   return (
//     <>
//       {currentItems &&
//         currentItems.map((item) => (
//           <div>
//             <h3>Item #{item}</h3>
//           </div>
//         ))}
//     </>
//   );
// }

const Testing = () => {
  const [sapreList, setsapreList] = useState([]);
  const [emeralList, setemeralList] = useState([]);
  const [Totalpage, setTotalpage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setdata] = useState([]);
  const [value, setValue] = useState(false);

  console.log(currentPage + 1);
  console.log(Totalpage);
  const Wingamehandller = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "GET",
      url: `https://api.wazir99.com/api/home/${userId}`,
      params: { page: currentPage + 1 },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.emeralList.last_page);
        setemeralList(response.data.data.emeralList.data);
        setsapreList(response.data.data.sapreList.data);
        setTotalpage(response.data.data.emeralList.last_page);
        setdata(response.data.data.emeralList.data);
        setValue(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  // Example items, to simulate fetching from another resources.
  useEffect(() => {
    Wingamehandller();
    console.log("ndcnjsdn");
  }, [value]);
  const itemsPerPage = 10;

  // const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  // const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = items.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(items.length / itemsPerPage);

  const offset = currentPage * itemsPerPage;
  console.log("offset", offset);

  const currentPageData = data
    .slice(offset, offset + itemsPerPage)
    .map((res, index) => <div key={index} src={data}></div>);
  console.log("currentpagedata", currentPageData);

  const pageCount = Math.ceil(data.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log("selected page", selectedPage);
    setCurrentPage(selectedPage);
    // const newOffset = (event.selected * itemsPerPage) % items.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    // setItemOffset(newOffset);
  };

  return (
    <div id="container" style={{ alignItems: "center", margin: 15 }}>
      {/* <Items currentItems={currentItems} /> */}
      <div className="Parity">
        <ul className="pa-ul">
          {/* Render your item data here */}
          <div className="head">Period </div>
          <div className="head">Price </div>
          <div className="head">Number </div>
          <div className="head">Result </div>
        </ul>
        <ul>
          {sapreList.map((item, index) => (
            <li key={index} className="pa-li">
              {/* Render your item data here */}
              <div>E{item.game_u_id}</div>
              <div>{item.price}</div>
              <div
                style={{
                  color:
                    item.opening_no === 2 ||
                      item.opening_no === 4 ||
                      item.opening_no === 6 ||
                      item.opening_no === 8
                      ? "red"
                      : item.opening_no === 1 ||
                        item.opening_no === 3 ||
                        item.opening_no === 7 ||
                        item.opening_no === 9
                        ? "green"
                        : item.opening_no === 5
                          ? "green" || "violet"
                          : item.opening_no === 0
                            ? "red" || "violet"
                            : "",
                }}
              >
                {item.opening_no}
              </div>
              {item.opening_no === 5 ? (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "green",
                      borderRadius: 100,
                      alignSelf: "center",
                      margin: "5px",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "violet",
                      borderRadius: 100,
                      alignSelf: "center",
                    }}
                  ></div>
                </div>
              ) : item.opening_no === 0 ? (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "red",
                      borderRadius: 100,
                      alignSelf: "center",
                      margin: "5px",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "violet",
                      borderRadius: 100,
                      alignSelf: "center",
                    }}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor:
                      item.opening_no === 2 ||
                        item.opening_no === 4 ||
                        item.opening_no === 6 ||
                        item.opening_no === 8
                        ? "red"
                        : item.opening_no === 1 ||
                          item.opening_no === 3 ||
                          item.opening_no === 7 ||
                          item.opening_no === 9
                          ? "green"
                          : "",
                    borderRadius: 100,
                    alignSelf: "center",
                  }}
                ></div>
              )}
            </li>
          ))}
        </ul>
      </div>
      {currentPageData}
      <div
        style={{ alignItems: "center", display: "flex", flexDirection: "row" }}
      >
        <ReactPaginate
          onClick={() => {
            setValue(true);
          }}
          containerClassName="pagination justify-content-center"
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={Totalpage}
          previousLabel="< "
          renderOnZeroPageCount={null}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

export default Testing;

// ReactDOM.render(
//   <PaginatedItems itemsPerPage={4} />,
//   document.getElementById("container")
// );
