import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faHouse, faUser, faMagnifyingGlass, faArrowLeft } from '@fortawesome/free-solid-svg-icons';


function Addaddress() {
    return (
        <div className="fullscreen">
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>AddBankcard</span>
                </div>
            </nav>
            <div className="input-card">
                <ul className="card-ul">
                    <li>
                        <p>Full Name</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Mobile Number</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Pincode</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>State</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Town/City</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Detail Address</p>
                        <input type="text" />
                    </li>
                </ul>
            </div>
            <div className="continue-btn">
                <button>Continue</button>
            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home'  >
                            <FontAwesomeIcon icon={faHouse} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span>Win</span>
                    </li>

                    <li>
                        <Link to='/' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#009688'} />
                        </Link>
                        <span style={{
                            color: '#009688'
                        }}>My</span>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default Addaddress;