import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import {
  faTrophy,
  faHouse,
  faUser,
  faMagnifyingGlass,
  faArrowsRotate,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
// import Countdown from "react-countdown";
import { faRectangleList } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Win() {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [topbar, settopbar] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [money, setmoney] = useState(0);
  const [balance, setbalance] = useState();
  const [emeralList, setemeralList] = useState([]);
  const [sapreList, setsapreList] = useState([]);
  const [Game, setGame] = useState();
  const [Game1, setGame1] = useState();
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [SelectedColor, setSelectedColor] = useState(null);
  const [Totalpage, setTotalpage] = useState("");
  const [countdown, setCountdown] = useState();
  const timerId = useRef(countdown);
  const [timerCount, setTimer] = useState(150);
  const [time, setTime] = useState(0);
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [value, setValue] = useState(false);
  const [Bet, setBet] = useState();
  const [status, setstatus] = useState();
  const [amount, setAmount] = useState(10);
  const [moneyvalue, setMoneyvalue] = useState(0);
  const [Record, setRecord] = useState([]);
  const [isopen, setisopen] = useState(false);

  console.log("bchdbcisdncjkdksnksdjn", currentPage);
  const Wingamehandller = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "GET",
      url: `https://api.wazir99.com/api/home/${userId}`,
      params: { page: currentPage + 1 },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.sapreList.data);
        setRecord(response.data.data.myList);
        setbalance(response.data.data.user.wallet_bal);
        setemeralList(response.data.data.emeralList.data);
        setsapreList(response.data.data.sapreList.data);
        setTotalpage(response.data.data.emeralList.last_page);
        setdata(response.data.data.emeralList.data);
        setdata1(response.data.data.sapreList.data);
        setValue(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const GameHandler = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "GET",
      url: "https://api.wazir99.com/api/getLiveGame",
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data[0].id);
        setGame(response.data.data[0].id);
        setGame1(response.data.data[1].id);
        setTime(response.data.data[0].counter_timer);
        setTimer(response.data.data[0].counter_timer);
        timerFun();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const BetsubmitHandler = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      url: "https://api.wazir99.com/api/betSubmit",
      data: {
        userid: `${userId}`,
        gameid: Game,
        amount: amount,
        betNumber:
          selectedNumber == 4
            ? "0"
            : selectedNumber == 5
              ? "1"
              : selectedNumber == 6
                ? "2"
                : selectedNumber == 7
                  ? "3"
                  : selectedNumber == 8
                    ? "4"
                    : selectedNumber == 9
                      ? "5"
                      : selectedNumber == 10
                        ? "6"
                        : selectedNumber == 11
                          ? "7"
                          : selectedNumber == 12
                            ? "8"
                            : selectedNumber == 13
                              ? "9"
                              : "",

        betColor:
          selectedNumber == 1
            ? "green"
            : selectedNumber == 2
              ? "voilet"
              : selectedNumber == 3
                ? "red"
                : "",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 1) {
          toast(response.data.msg);
        }
        if (response.data.status === 0) {
          toast(response.data.msg);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  console.log("game", Game);
  console.log("amount", amount);
  console.log("select number", selectedNumber);
  console.log("selectcolor", SelectedColor);
  // const GetResult = () => {
  //   const userId = localStorage.getItem("userid");
  //   console.log(userId);
  //   const options = {
  //     method: "GET",
  //     url: "https://wazir99.com/gameResultDec57yhju",
  //   };

  //   axios
  //     .request(options)
  //     .then(function (response) {
  //       console.log(response.data.data);
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // };

  const favHandler = (ind) => {
    const tempData = [];
    // console.log('OUTSIDE',id)

    Record.map((item, index) => {
      if (ind === index) {
        if (item.isselected == true) {
          tempData.push({ ...item, isselected: false });
        } else {
          tempData.push({ ...item, isselected: true });
        }
      } else {
        // setRecords(false)
        if (item.isselected == true) {
          tempData.push({ ...item, isselected: true });
        } else {
          tempData.push({ ...item, isselected: false });
        }
      }

      setRecord(tempData);
    });
  };

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
    openModal();
  };

  const handleIncreaseAmount = () => {
    const qty =
      moneyvalue == 1
        ? amount + 10
        : moneyvalue == 2
          ? amount + 100
          : moneyvalue == 3
            ? amount + 1000
            : moneyvalue == 4
              ? amount + 10000
              : amount + 10;
    setAmount(qty);
  };

  const handleDecreaseAmount = () => {
    const qty =
      moneyvalue == 1
        ? amount - 10
        : moneyvalue == 2
          ? amount - 100
          : moneyvalue == 3
            ? amount - 1000
            : moneyvalue == 4
              ? amount - 10000
              : amount - 10;
    setAmount(qty);
  };

  const [isAgreeChecked, setIsAgreeChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsAgreeChecked((prevChecked) => !prevChecked);
  };

  const handleConfirm = () => {
    if (isAgreeChecked) {
      closeModal();
      BetsubmitHandler();
    } else {
    }
  };

  const openModal = (buttonName) => {
    setSelectedButton(buttonName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedButton(null);
  };

  const handleTabClick = (index) => {
    settopbar(index);
  };

  const handleMoneyClick = (index) => {
    setmoney(index);
  };

  const handleRefresh = () => {
    // Reload the page
    window.location.reload();
  };
  const formateTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time - minutes * 60);

    if (minutes <= 9) minutes = "0" + minutes;
    if (seconds <= 9) seconds = "0" + seconds;

    return minutes + " : " + seconds;
  };

  let interval;
  const timerFun = () => {
    interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 30
          ? setIsActive(true) || setModalVisible(false)
          : setIsActive(false);
        lastTimerCount <= 1 && clearInterval(interval);
        lastTimerCount <= 1 && GameHandler();
        lastTimerCount <= 1 && Wingamehandller();
        return lastTimerCount - 1;
      });
    }, 1000);
  };
  useEffect(() => {
    Wingamehandller();

    // BetsubmitHandler();
  }, [value]);
  useEffect(() => {
    GameHandler();
  }, []);

  // useEffect(() => {
  //   GetResult();
  // }, []);
  const offset = currentPage * itemsPerPage;
  console.log("offset", offset);

  const currentPageData = data
    .slice(offset, offset + itemsPerPage)
    .map((res, index) => <div key={index} src={data}></div>);
  console.log("currentpagedata", currentPageData);

  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = ({ selected: selectedPage }) => {
    console.log("selected page", selectedPage);
    setCurrentPage(selectedPage);
  };

  return (
    <div className="fullscreen">
      <div className="mine-top">
        <div className="mine-info">
          <p className="balance">Available balance: ₹ {balance}</p>
          <div className="mine-info-btn">
            <div className="btn-win">
              <Link to="/Recharge">
                <button className="one-btn">Recharge</button>
              </Link>
            </div>
            <div className="refresh" onClick={handleRefresh}>
              <FontAwesomeIcon icon={faArrowsRotate} color={"#fafafa"} />
            </div>
          </div>
        </div>
      </div>
      <div className="main">
        <ul className="main-nav">
          <li
            className={topbar === 0 ? "active-tab-win" : ""}
            onClick={() => handleTabClick(0)}
          >
            Sapre
          </li>
          <li
            className={topbar === 1 ? "active-tab-win" : ""}
            onClick={() => handleTabClick(1)}
          >
            Emerd
          </li>
        </ul>
        <div className="center-text">
          <ul className="center-top">
            <li className="left-li">
              <ol className="top-ol">
                <FontAwesomeIcon icon={faTrophy} />
                <span></span>
              </ol>
              <ol className="bot-ol">
                {topbar === 0 ? (
                  <>S{Game}</>
                ) : topbar === 1 ? (
                  <>E{Game1}</>
                ) : (
                  ""
                )}{" "}
              </ol>
            </li>
            <li className="right-li">
              <ol className="top-ol">
                <span>Count Down</span>
              </ol>
              <ol className="bot-ol">{formateTime(timerCount)}</ol>
            </li>
          </ul>
        </div>
        <ToastContainer />

        {!isActive ? (
          <>
            <div className="btn-center">
              <button
                // disabled={true}
                onClick={() => {
                  // openModal("Green")
                  handleNumberClick(1);
                }}
                className="back-one"
              >
                {" "}
                Join Green{" "}
              </button>
              <button onClick={() => {
                // openModal("Violet")
                handleNumberClick(2);
              }} className="back-two">
                {" "}
                Join Violet{" "}
              </button>
              <button onClick={() => {
                // openModal("Red")
                handleNumberClick(3);
              }} className="back-three">
                {" "}
                Join Red{" "}
              </button>
            </div>
            <ul className="center-notes">
              <li>
                <ol className="lenier" onClick={() => handleNumberClick(4)}>
                  0
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(76, 175, 80)" }}
                  onClick={() => handleNumberClick(5)}
                >
                  1
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(244, 67, 54)" }}
                  onClick={() => handleNumberClick(6)}
                >
                  2
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(76, 175, 80)" }}
                  onClick={() => handleNumberClick(7)}
                >
                  3
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(244, 67, 54)" }}
                  onClick={() => handleNumberClick(8)}
                >
                  4
                </ol>
              </li>
              <li>
                <ol className="lenier1" onClick={() => handleNumberClick(9)}>
                  5
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(244, 67, 54)" }}
                  onClick={() => handleNumberClick(10)}
                >
                  6
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(76, 175, 80)" }}
                  onClick={() => handleNumberClick(11)}
                >
                  7
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(244, 67, 54)" }}
                  onClick={() => handleNumberClick(12)}
                >
                  8
                </ol>
              </li>
              <li>
                <ol
                  style={{ backgroundColor: "rgb(76, 175, 80)" }}
                  onClick={() => handleNumberClick(13)}
                >
                  9
                </ol>
              </li>
            </ul>
          </>
        ) : (
          <>
            <div className="btn-center">
              <button
                disabled={true}
                // onClick={() => openModal("Green")}
                className="disable"
              >
                {" "}
                Join Green{" "}
              </button>
              <button
                // onClick={() => openModal("Violet")}
                className="disable"
                disabled={true}
              >
                {" "}
                Join Violet{" "}
              </button>
              <button
                // onClick={() => openModal("Red")}
                className="disable"
                disabled={true}
              >
                {" "}
                Join Red{" "}
              </button>
            </div>
            <ul className="center-notes">
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(0)}
                >
                  0
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(1)}
                >
                  1
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(2)}
                >
                  2
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(3)}
                >
                  3
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(4)}
                >
                  4
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(5)}
                >
                  5
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(6)}
                >
                  6
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(7)}
                >
                  7
                </ol>
              </li>
              <li>
                <ol
                  className="disable"
                // onClick={() => handleNumberClick(8)}
                >
                  8
                </ol>
              </li>
              <li>
                <ol className="disable" onClick={() => handleNumberClick(9)}>
                  9
                </ol>
              </li>
            </ul>
          </>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          data={selectedButton}
        >
          {/* Render content inside the modal */}
          <p
            className="branch-ttl"
            style={{
              backgroundColor:
                selectedNumber == 1
                  ? "#4caf50"
                  : selectedNumber == 2
                    ? "#9c27b0"
                    : selectedNumber == 3
                      ? "#f44336"
                      : "#2196f3",
            }}
          >
            {selectedButton ? (
              selectedButton
            ) : (
              <p>
                select :{" "}
                {selectedNumber == 4
                  ? "0"
                  : selectedNumber == 5
                    ? "1"
                    : selectedNumber == 6
                      ? "2"
                      : selectedNumber == 7
                        ? "3"
                        : selectedNumber == 8
                          ? "4"
                          : selectedNumber == 9
                            ? "5"
                            : selectedNumber == 10
                              ? "6"
                              : selectedNumber == 11
                                ? "7"
                                : selectedNumber == 12
                                  ? "8"
                                  : selectedNumber == 13
                                    ? "9"
                                    : selectedNumber == 1
                                      ? "green"
                                      : selectedNumber == 2
                                        ? "violet"
                                        : selectedNumber == 3
                                          ? "red"
                                          : ""}
              </p>
            )}
          </p>
          <div className="branch-content">
            <p className="money">Contract Money</p>
            <div className="choose-money">
              <ul>
                <li
                  className={money === 0 ? "active-money" : ""}
                  onClick={() => {
                    handleMoneyClick(0);
                    setAmount(10);
                    setMoneyvalue(1);
                  }}
                >
                  10
                </li>
                <li
                  className={money === 1 ? "active-money" : ""}
                  onClick={() => {
                    handleMoneyClick(1);
                    setAmount(100);
                    setMoneyvalue(2);
                  }}
                >
                  100
                </li>
                <li
                  className={money === 2 ? "active-money" : ""}
                  onClick={() => {
                    handleMoneyClick(2);
                    setAmount(1000);
                    setMoneyvalue(3);
                  }}
                >
                  1000
                </li>
                <li
                  className={money === 3 ? "active-money" : ""}
                  onClick={() => {
                    handleMoneyClick(3);
                    setAmount(10000);
                    setMoneyvalue(4);
                  }}
                >
                  10000
                </li>
              </ul>
            </div>
            <p className="money">Number</p>
            <div className="stepper">
              <div className="van-stepper">
                <button onClick={handleDecreaseAmount}>-</button>
                <span>{amount}</span>{" "}
                {/* Step 4: Update the amount in the span tag */}
                <button onClick={handleIncreaseAmount}>+</button>
              </div>
            </div>
            <p className="money">
              The contract money is
              <span> {amount}</span>
            </p>
            <div className="agree">
              <div className="check-box">
                <input
                  type="checkbox"
                  id="agreeCheckbox"
                  checked={isAgreeChecked}
                  onChange={handleCheckboxChange}
                />
                <span className="van-checkbox-lbl">
                  <label htmlFor="agreeCheckbox">I agree</label>
                  <Link to="/Privacypolicy"> PRESALE RULE</Link>
                </span>
              </div>
            </div>
            <div className="modal-buttons">
              <button onClick={closeModal}>Cancel</button>
              <button
                onClick={handleConfirm}
                // onChange={(e) => setBet(e.target.value)}
                style={{ color: "#00897B" }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>

      <div className="nav-content">
        <div className="content">
          {topbar === 0 ? (
            <>
              <div className="content-con">
                <div className="content-title">
                  <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
                  <p>Sapre Record</p>
                </div>
                <div className="Parity">
                  <ul className="pa-ul">
                    {/* Render your item data here */}
                    <div className="head">Period </div>
                    {/* <div className="head">Price </div> */}
                    <div className="head">Number </div>
                    <div className="head">Result </div>
                  </ul>
                  <ul>
                    {sapreList.map((item, index) => (
                      <li key={index} className="pa-li">
                        {/* Render your item data here */}
                        <div>
                          {item.type === "emeral"
                            ? "E"
                            : item.type === "sapre"
                              ? "S"
                              : ""}
                          {item.id}
                        </div>
                        <div
                          style={{
                            color:
                              item.opening_no === 2 ||
                                item.opening_no === 4 ||
                                item.opening_no === 6 ||
                                item.opening_no === 8
                                ? "red"
                                : item.opening_no === 1 ||
                                  item.opening_no === 3 ||
                                  item.opening_no === 7 ||
                                  item.opening_no === 9
                                  ? "green"
                                  : item.opening_no === 5
                                    ? "green" || "violet"
                                    : item.opening_no === 0
                                      ? "red" || "violet"
                                      : "",
                          }}
                        >
                          {item.opening_no}
                        </div>
                        {item.opening_no === 5 ? (
                          <div style={{ display: "flex", marginLeft: '-26px' }}>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "green",
                                borderRadius: 100,
                                alignSelf: "center",
                                margin: "5px",
                              }}
                            ></div>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "violet",
                                borderRadius: 100,
                                alignSelf: "center",
                              }}
                            ></div>
                          </div>
                        ) : item.opening_no === 0 ? (
                          <div style={{ display: "flex", marginLeft: '-26px' }}>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "red",
                                borderRadius: 100,
                                alignSelf: "center",
                                margin: "5px",
                              }}
                            ></div>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "violet",
                                borderRadius: 100,
                                alignSelf: "center",
                              }}
                            ></div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 20,
                              height: 20,
                              backgroundColor:
                                item.opening_no === 2 ||
                                  item.opening_no === 4 ||
                                  item.opening_no === 6 ||
                                  item.opening_no === 8
                                  ? "red"
                                  : item.opening_no === 1 ||
                                    item.opening_no === 3 ||
                                    item.opening_no === 7 ||
                                    item.opening_no === 9
                                    ? "green"
                                    : "",
                              borderRadius: 100,
                              alignSelf: "center",
                            }}
                          ></div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                {currentPageData}
                <div
                  style={{
                   
                  }}
                >
                  <ReactPaginate
                    onClick={() => {
                      setValue(true);
                      // console.log("jvnvsdcjndsck");
                    }}
                    containerClassName="pagination justify-content-center"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={Totalpage}
                    previousLabel="< "
                    renderOnZeroPageCount={null}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                  />
                </div>
                {/* <div className="pagination">
                  <button
                    onClick={handlePreviousPage}
                    style={{ marginRight: "5%" }}
                  >
                    &lt;{" "}
                  </button>
                  {Array.from(
                    { length: Math.ceil(items.length / itemsPerPage) },
                    (_, i) => i + 1
                  ).map((pageNumber) => (
                    <button
                      key={pageNumber}
                      className={currentPage === pageNumber ? "active" : ""}
                      onClick={() => handlePageChange(pageNumber)}
                      style={{ marginRight: "5%" }}
                    >
                      {pageNumber}
                    </button>
                  ))}
                  <button onClick={handleNextPage}> &gt;</button>
                </div> */}
              </div>

              {/* <Items currentItems={currentItems} /> */}
            </>
          ) : topbar === 1 ? (
            <>
              <div className="content-con">
                <div className="content-title">
                  <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
                  <p>Emerd Record</p>
                </div>
                <div className="Parity">
                  <ul className="pa-ul">
                    {/* Render your item data here */}
                    <div className="head">Period </div>
                    <div className="head">Number </div>
                    <div className="head">Result </div>
                  </ul>
                  <ul>
                    {emeralList.map((item, index) => (
                      <li key={index} className="pa-li">
                        {/* Render your item data here */}
                        <div>
                          {item.type === "emeral"
                            ? "E"
                            : item.type === "sapre"
                              ? "S"
                              : ""}
                          {item.id}
                        </div>
                        <div
                          style={{
                            color:
                              item.opening_no === 2 ||
                                item.opening_no === 4 ||
                                item.opening_no === 6 ||
                                item.opening_no === 8
                                ? "red"
                                : item.opening_no === 1 ||
                                  item.opening_no === 3 ||
                                  item.opening_no === 7 ||
                                  item.opening_no === 9
                                  ? "green"
                                  : item.opening_no === 5
                                    ? "green" || "violet"
                                    : item.opening_no === 0
                                      ? "red" || "violet"
                                      : "",
                          }}
                        >
                          {item.opening_no}
                        </div>
                        {item.opening_no === 5 ? (
                          <div style={{ display: "flex", marginLeft: '-26px' }}>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "green",
                                borderRadius: 100,
                                alignSelf: "center",
                                margin: "5px",
                              }}
                            ></div>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "violet",
                                borderRadius: 100,
                                alignSelf: "center",
                              }}
                            ></div>
                          </div>
                        ) : item.opening_no === 0 ? (
                          <div style={{ display: "flex", marginLeft: '-26px' }}>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "red",
                                borderRadius: 100,
                                alignSelf: "center",
                                margin: "5px",
                              }}
                            ></div>
                            <div
                              style={{
                                width: 20,
                                height: 20,
                                backgroundColor: "violet",
                                borderRadius: 100,
                                alignSelf: "center",
                              }}
                            ></div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 20,
                              height: 20,
                              backgroundColor:
                                item.opening_no === 2 ||
                                  item.opening_no === 4 ||
                                  item.opening_no === 6 ||
                                  item.opening_no === 8
                                  ? "red"
                                  : item.opening_no === 1 ||
                                    item.opening_no === 3 ||
                                    item.opening_no === 7 ||
                                    item.opening_no === 9
                                    ? "green"
                                    : "",
                              borderRadius: 100,
                              alignSelf: "center",
                            }}
                          ></div>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
                {currentPageData}
                <div className="paginate"
                  style={{
                    
                  }}
                >
                  <ReactPaginate
                    onClick={() => {
                      setValue(true);
                    }}
                    containerClassName="pagination justify-content-center"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={Totalpage}
                    previousLabel="< "
                    renderOnZeroPageCount={null}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="content">
          <div className="content-con">
            <div className="content-title">
              <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
              <p>My Record</p>
            </div>
            <div className="My-Order">
              {Record.map((item, index) => (
                <div key={index} style={{ width: "100%" }}>
                  <ul
                    onClick={() => {
                      favHandler(index);
                      setisopen(!isopen);
                    }}
                    style={{ width: "100%", margin: 5 }}
                  >
                    <li
                      style={{
                        width: "80%",
                        justifyContent: "space-between",
                        padding: 2,
                      }}
                    >
                      <ol style={{ width: "40%" }}>
                        {item.game_type === "sapre"
                          ? "S"
                          : item.game_type === "emeral"
                            ? "E"
                            : ""}
                        {item.game_id}
                      </ol>
                      <ol
                        style={{
                          color:
                            item.status == "Lost"
                              ? "red"
                              : item.status == "Win"
                                ? "Green"
                                : item.status == "wait"
                                  ? "white"
                                  : "",
                          width: "40%",
                        }}
                      >
                        {item.status}
                      </ol>
                      {/* <ol
                        style={{
                          color:
                            item.status == "Lost"
                              ? "red"
                              : item.status == "Win"
                              ? "green"
                              : "",
                          width: "20%",
                        }}
                      >
                        {item.amount_earn}
                      </ol> */}
                    </li>
                    <li>
                      {item.isselected === true ? (
                        <FontAwesomeIcon icon={faChevronUp} color={"#808080"} />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          color={"#808080"}
                        />
                      )}
                    </li>
                  </ul>
                  {item.isselected === true ? (
                    <ul className="drop">
                      <li>
                        <p>Period </p>
                        <p>{item.game_id}</p>
                      </li>
                      <li>
                        <p>Contract Money</p>
                        <p>{item.bet_amount}</p>
                      </li>
                      <li>
                        <p>Delivery </p>
                        <p
                          style={{
                            color:
                              item.status === "Lost"
                                ? "red"
                                : item.status === "Win"
                                  ? "green"
                                  : "",
                          }}
                        >
                          {item.amount_earn}
                        </p>
                      </li>
                      <li>
                        <p>Fee</p>
                        <p>{item.fees}</p>
                      </li>
                      <li>
                        <p>Result</p>
                        <p>
                          {item.result_num} {item.result_color}
                        </p>
                      </li>
                      <li>
                        <p>Select</p>
                        <p
                          style={{
                            fontFamily: "Arimo-Medium",
                            color:
                              item.bet_no == 1 ||
                                item.bet_no == 3 ||
                                item.bet_no == 7 ||
                                item.bet_no == 9 ||
                                item.bet_no == 5
                                ? "green"
                                : item.bet_color == "voilet"
                                  ? "#9C27B0"
                                  : item.bet_color == "red"
                                    ? "red"
                                    : item.bet_color == "green"
                                      ? "green"
                                      : "red",
                            fontSize: 18,
                          }}
                        >
                          {item.bet_no == 1 ||
                            item.bet_no == 3 ||
                            item.bet_no == 7 ||
                            item.bet_no == 9
                            ? ` ${item.bet_no} green`
                            : item.bet_no == 2 ||
                              item.bet_no == 4 ||
                              item.bet_no == 6 ||
                              item.bet_no == 8
                              ? ` ${item.bet_no} red`
                              : item.bet_no == 0
                                ? `${item.bet_no} red, voilet `
                                : item.bet_no == 5
                                  ? `${item.bet_no} green, voilet`
                                  : `${item.bet_color} `}
                        </p>
                      </li>
                      <li>
                        <p>Status</p>
                        <p>{item.status}</p>
                      </li>
                      <li>
                        <p>Amount</p>
                        <p>{item.amount_earn}</p>
                      </li>
                      <li>
                        <p>Create Time</p>
                        <p>{item.created_at}</p>
                      </li>
                      <li>
                        <p>Type</p>
                        <p>{item.game_type}</p>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="odr">
          <button
            className="one-btn order-btn"
            onClick={() => {
              navigate("/Myorder");
            }}
          >
            {" "}
            My Orders{" "}
          </button>
        </div>
      </div>

      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home1">
              <FontAwesomeIcon icon={faHouse} color={"#808080"} />
            </Link>
            <span style={{ color: "#808080" }}>Home</span>
          </li>

          <li>
            <Link to="/Search1">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Win">
              <FontAwesomeIcon icon={faTrophy} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>Win</span>
          </li>

          <li>
            <Link to="/My1" className="link">
              <FontAwesomeIcon icon={faUser} color={"#808080"} />
            </Link>
            <span
              style={{
                color: "#808080",
              }}
            >
              My
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Win;

// const items = [
//   { period: "20230725331", price: "30029", number: "1", result: "1" },
//   { period: "20230725332", price: "30023", number: "5", result: "5" },
//   { period: "20230725332", price: "30023", number: "2", result: "2" },
//   { period: "20230725331", price: "30029", number: "1", result: "1" },
//   { period: "20230725332", price: "30023", number: "5", result: "5" },
//   { period: "20230725332", price: "30023", number: "2", result: "2" },
//   { period: "20230725331", price: "30029", number: "1", result: "1" },
//   { period: "20230725332", price: "30023", number: "5", result: "5" },
//   { period: "20230725332", price: "30023", number: "2", result: "2" },
//   { period: "20230725331", price: "30029", number: "1", result: "1" },
//   { period: "20230725332", price: "30023", number: "5", result: "5" },
//   { period: "20230725332", price: "30023", number: "2", result: "2" },
//   { period: "20230725331", price: "30029", number: "1", result: "1" },
//   { period: "20230725332", price: "30023", number: "5", result: "5" },
//   { period: "20230725332", price: "30023", number: "2", result: "2" },
//   { period: "20230725331", price: "30029", number: "1", result: "1" },
//   { period: "20230725332", price: "30023", number: "5", result: "5" },
//   { period: "20230725332", price: "30023", number: "2", result: "2" },
//   { period: "20230725331", price: "30029", number: "1", result: "1" },
//   { period: "20230725332", price: "30023", number: "5", result: "5" },
//   { period: "20230725332", price: "30023", number: "2", result: "2" },
// ];

// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

// function Items({ currentItems }) {
//   return (
//     <>
//       {currentItems &&
//         currentItems.map((item) => (
//           <div>
//             <h3>Item # sjhsbjfghdffh{item}</h3>
//           </div>
//         ))}
//     </>
//   );
// }

// const [itemOffset, setItemOffset] = useState(0);
// const endOffset = itemOffset + itemsPerPage;
// console.log(`Loading items from ${itemOffset} to ${endOffset}`);
// const currentItems = items.slice(itemOffset, endOffset);
// const pageCount = Math.ceil(items.length / itemsPerPage);

// // Invoke when user click to request another page.
// const handlePageClick = (event) => {
//   const newOffset = (event.selected * itemsPerPage) % items.length;
//   console.log(
//     `User requested page number ${event.selected}, which is offset ${newOffset}`
//   );
//   setItemOffset(newOffset);
// };

// const indexOfLastItem = currentPage * itemsPerPage;
// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
// const itemsToShow = items.slice(indexOfFirstItem, indexOfLastItem);

// const handlePageChange = (pageNumber) => {
//   setCurrentPage(pageNumber);
// };

// const handlePreviousPage = () => {
//   if (currentPage > 1) {
//     setCurrentPage((prevPage) => prevPage - 1);
//   }
// };

// const handleNextPage = () => {
//   const Totalpage = Math.ceil(items.length / itemsPerPage);
//   if (currentPage < Totalpage) {
//     setCurrentPage((prevPage) => prevPage + 1);
//   }
// };

{
  /* <Countdown
                  date={Date.now() + 180000}
                  onTick={(val) => {
                    console.log(val.total);

                    if (val.total === 30000) {
                      setIsActive(true);
                    }
                  }}
                  onComplete={(val) => {
                    setIsActive(false);
                  }}
                ></Countdown> */
}

{
  /* <div className="pagination">
                  <button
                    onClick={handlePreviousPage}
                    style={{ marginRight: "5%" }}
                  >
                    &lt;{" "}
                  </button>
                  {Array.from(
                    { length: Math.ceil(items.length / itemsPerPage) },
                    (_, i) => i + 1
                  ).map((pageNumber) => (
                    <button
                      key={pageNumber}
                      className={currentPage === pageNumber ? "active" : ""}
                      onClick={() => handlePageChange(pageNumber)}
                      style={{ marginRight: "5%" }}
                    >
                      {pageNumber}
                    </button>
                  ))}
                  <button onClick={handleNextPage}> &gt;</button>
                </div> */
}

{
  /* <div className="pagination">
                  <button
                    onClick={handlePreviousPage}
                    style={{ marginRight: "5%" }}
                  >
                    &lt;{" "}
                  </button>
                  {Array.from(
                    { length: Math.ceil(items.length / itemsPerPage) },
                    (_, i) => i + 1
                  ).map((pageNumber) => (
                    <button
                      key={pageNumber}
                      className={currentPage === pageNumber ? "active" : ""}
                      onClick={() => handlePageChange(pageNumber)}
                      style={{ marginRight: "5%" }}
                    >
                      {pageNumber}
                    </button>
                  ))}
                  <button onClick={handleNextPage}> &gt;</button>
                </div> */
}

{
  /* <div className="pagination">
                  <button
                    onClick={handlePreviousPage}
                    style={{ marginRight: "5%" }}
                  >
                    &lt;{" "}
                  </button>
                  {Array.from(
                    { length: Math.ceil(items.length / itemsPerPage) },
                    (_, i) => i + 1
                  ).map((pageNumber) => (
                    <button
                      key={pageNumber}
                      className={currentPage === pageNumber ? "active" : ""}
                      onClick={() => handlePageChange(pageNumber)}
                      style={{ marginRight: "5%" }}
                    >
                      {pageNumber}
                    </button>
                  ))}
                  <button onClick={handleNextPage}> &gt;</button>
                </div> */
}
