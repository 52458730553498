import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrophy, faHouse, faUser, faMagnifyingGlass, faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';



function Complaint() {
    const [topbar, settopbar] = useState(0)
    const handleTabClick = (index) => {
        settopbar(index);
    };

    const redirectToAddBankcard = () => {
        // Redirect to the "Orders" page
        window.location.href = "/AddBankcard";
    };

    return (
        <div className="fullscreen">
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>Complaints & Suggestions</span>
                </div>

                <div className="right">
                    <FontAwesomeIcon icon={faPlus} className="bar" onClick={redirectToAddBankcard} />
                </div>
            </nav>
            <div className="completed-box">
                <ul className="completed-nav">
                    <li className={topbar === 0 ? "active-tab-c" : ""}
                        onClick={() => handleTabClick(0)} >COMPLETED</li>
                    <li className={topbar === 1 ? "active-tab-c" : ""}
                        onClick={() => handleTabClick(1)}>WAIT</li>
                </ul>
            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home'  >
                            <FontAwesomeIcon icon={faHouse} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span>Win</span>
                    </li>

                    <li>
                        <Link to='/' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#009688'} />
                        </Link>
                        <span style={{
                            color: '#009688'
                        }}>My</span>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default Complaint;