import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faUser,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../Images/1.jpg";
import img2 from "../Images/2.jpg";
import img3 from "../Images/3.jpg";
import img4 from "../Images/4.jpg";
import img5 from "../Images/5.jpg";
import img6 from "../Images/6.jpg";
import img7 from "../Images/7.jpg";
import img8 from "../Images/8.jpg";
import img9 from "../Images/9.jpg";
import img10 from "../Images/10.jpg";
import img11 from "../Images/11.jpg";
import img12 from "../Images/12.jpg";
import img13 from "../Images/13.jpg";
import img14 from "../Images/14.jpg";
import img15 from "../Images/15.jpg";
import img16 from "../Images/16.jpg";

function Search() {
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      description:
        "Joyalukkas 18k (750) Rose Gold and Solitaire Pendant for Girl",
      price: "₹ 38576.00",
      image: img1,
    },
    {
      id: 2,
      description:
        "Ratnavali Jewels American Diamond Traditional Fashion Jewellery Green Necklace Pendant Set with Earring for Women/Girls RV2916G",
      price: "₹ 38576.00",
      image: img2,
    },
    {
      id: 3,
      description:
        "Swasti Jewels Heart Shape Fashion Jewellery Set Pendant Earrings for Women",
      price: "₹ 4559.00",
      image: img3,
    },
    {
      id: 4,
      description:
        "Om Jewells Rhodium Plated Blue Crystal Jewellery Combo of Designer Drop Pendant Set with Adjustable Bangle Kada and Adjustable Solitaire Finger Ring for Girls and Women CO1000209",
      price: "₹ 1599.00",
      image: img4,
    },
    {
      id: 5,
      description:
        "Sukkhi Gleaming Pearl Gold Plated Wedding Jewellery Kundan Peacock Meenakari Multi-String Necklace Set for Women (2191NGLDPP1560)",
      price: "₹ 1745.00",
      image: img5,
    },
    {
      id: 6,
      description:
        "Ananth Jewels 925 Sterling Silver BIS Hallmarked Heart Bracelet for Women",
      price: "₹ 9000.00",
      image: img6,
    },
    {
      id: 7,
      description:
        "Handicraft Kottage ® 1gm 22Ct Gold Plated Pendant and Chain for Men/Women/Girls",
      price: "₹ 999.00",
      image: img7,
    },
    {
      id: 8,
      description:
        "Mansiyaorange Combo of Two Party One Gram Gold Forming Long Haram and Choker Multi Color Jewellery Necklace/Juelry/jwelry Set Jewellery for Women",
      price: "₹ 3199.00",
      image: img8,
    },
    {
      id: 9,
      description:
        "Young & Forever Fashion Jewellery Elite Rose Gold Plated Geometric Shape Stud Earring Pendant Set for Women Princess Length Delicate Chain Cubic Zirconia Necklace Set for Girls Jewelry",
      price: "₹ 4450.00",
      image: img9,
    },
    {
      id: 10,
      description:
        "Ratnavali Jewels American Diamond Cz Gold Plated Necklace Set Tennis Necklace Single Line Solitaire Set With Chain & Earring For Women",
      price: "₹ 4000.00",
      image: img10,
    },
    {
      id: 11,
      description:
        "chandrika pearls gems & jewellers Sania Mirza Style Without Piercing Clip on Pressing Type Nose Ring for Women & Girls",
      price: "₹ 278.00",
      image: img11,
    },
    {
      id: 12,
      description:
        "Chandrika Pearls Gems & Jewellers Sterling Silver Pendant Earring with Swarovski Crystal for Girls",
      price: "₹ 1060.00",
      image: img12,
    },
    {
      id: 13,
      description:
        "Chandrika Pearls Gems & Jewellers 92.5 Sterling Silver Real Diamond Valentine Crown King Queen Ring for Girls & Women",
      price: "₹ 1920.00",
      image: img13,
    },
    {
      id: 14,
      description:
        "Chandrika Pearls Gems & Jewellers 925 Pure Sterling Silver Letters Initial Pendant with Gold Polish (R)",
      price: "₹ 476.00",
      image: img14,
    },
    {
      id: 15,
      description:
        "Chandrika Pearls Gems & Jewellers Dhanteras Brass Hindu Puja Camphor Burner Lamp Panch Aarti - 5 Face For Puja",
      price: "₹ 1880.00",
      image: img15,
    },
    {
      id: 16,
      description:
        "Jewels Galaxy Designer American Diamond Gold Plated Bangles for Women/Girls",
      price: "₹ 1999.00",
      image: img16,
    },
  ];

  return (
    <div className="search">
      <div className="search_box">
        <input placeholder="Search For Goods" type="text" />
      </div>
      <div className="item_list">
        <div className="list_content">
          <ul className="list_ul">
            {data.map((item, index) => (
              <li
                key={index}
                className="list_li"
                onClick={() => {
                  navigate(`/Product/${item.id}`);
                }}
              >
                <ol>
                  <div className="list_img_box">
                    <div className="list_img">
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                  <div className="description info">{item.description}</div>
                  <p className="price">{item.price}</p>
                </ol>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home">
              <FontAwesomeIcon icon={faHouse} color={"#808080"} />
            </Link>
            <span>Home</span>
          </li>

          <li>
            <Link to="/search">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>Search</span>
          </li>

          <li>
            <Link to="/Login" className="link">
              <FontAwesomeIcon icon={faUser} color={"#808080"} />
            </Link>
            <span>My</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Search;
