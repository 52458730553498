import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrophy,
  faHouse,
  faUser,
  faMagnifyingGlass,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import axios from "axios";

function Myorder() {
  const [topbar, settopbar] = useState(0);
  const [isopen, setisopen] = useState(false);
  const [data, setdata] = useState([]);

  const HistoryHandler = (typeData) => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "POST",
      url: "https://api.wazir99.com/api/history",
      data: {
        userid: `${userId}`,
        type: typeData,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.list);
        setdata(response.data.data.list);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const favHandler = (ind) => {
    const tempData = [];
    // console.log('OUTSIDE',id)

    data.map((item, index) => {
      if (ind === index) {
        if (item.isselected == true) {
          tempData.push({ ...item, isselected: false });
        } else {
          tempData.push({ ...item, isselected: true });
        }
      } else {
        // setRecords(false)
        if (item.isselected == true) {
          tempData.push({ ...item, isselected: true });
        } else {
          tempData.push({ ...item, isselected: false });
        }
      }

      setdata(tempData);
    });
  };

  const handleTabClick = (index) => {
    settopbar(index);
  };

  useEffect(() => {
    HistoryHandler("all");
  }, []);

  return (
    <div className="fullscreen">
      <div className="top-box">
        <nav className="top-nav">
          <div className="left">
            <Link to="/My1">
              <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
            </Link>
            <span>Orders</span>
          </div>
        </nav>
        <div>
          <ul className="order-nav">
            <li
              className={topbar === 0 ? "active-tab" : ""}
              onClick={() => {
                handleTabClick(0);
                HistoryHandler("all");
              }}
            >
              ALL
            </li>
            <li
              className={topbar === 1 ? "active-tab" : ""}
              onClick={() => {
                handleTabClick(1);
                HistoryHandler("wait");
              }}
            >
              UNDELIVER
            </li>
            <li
              className={topbar === 2 ? "active-tab" : ""}
              onClick={() => {
                handleTabClick(2);
                HistoryHandler("lost");
              }}
            >
              UNRECEIVE
            </li>
            <li
              className={topbar === 3 ? "active-tab" : ""}
              onClick={() => {
                handleTabClick(3);
                HistoryHandler("win");
              }}
            >
              SUCCESS
            </li>
          </ul>
        </div>
      </div>
      <div className="contentbox">
        {topbar === 0 ? (
          <>
            <div className="content">
              <div className="content-con">
                <div className="My-Order">
                  {data.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <ul
                        onClick={() => {
                          favHandler(index);
                          setisopen(!isopen);
                        }}
                        style={{ width: "100%", margin: 5 }}
                      >
                        <li
                          style={{
                            width: "80%",
                            justifyContent: "space-between",
                            padding: 2,
                          }}
                        >
                          <ol style={{ width: "40%" }}>
                            {item.game_type === "sapre"
                              ? "S"
                              : item.game_type === "emeral"
                                ? "E"
                                : ""}
                            {item.game_id}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "Green"
                                    : item.status == "wait"
                                      ? "white"
                                      : "",
                              width: "40%",
                            }}
                          >
                            {item.status}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "green"
                                    : "",
                              width: "20%",
                            }}
                          >
                            {item.amount_earn}
                          </ol>
                        </li>
                        <li>
                          {item.isselected === true ? (
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              color={"#808080"}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              color={"#808080"}
                            />
                          )}
                        </li>
                      </ul>
                      {item.isselected === true ? (
                        <ul className="drop">
                          <li>
                            <p>Period </p>
                            <p>{item.game_id}</p>
                          </li>
                          <li>
                            <p>Contract Money</p>
                            <p>{item.bet_amount}</p>
                          </li>
                          <li>
                            <p>Delivery </p>
                            <p
                              style={{
                                color:
                                  item.status === "Lost"
                                    ? "red"
                                    : item.status === "Win"
                                      ? "green"
                                      : "",
                              }}
                            >
                              {item.amount_earn}
                            </p>
                          </li>
                          <li>
                            <p>Fee</p>
                            <p>{item.fees}</p>
                          </li>
                          <li>
                            <p>Result</p>
                            <p>
                              {item.result_num} {item.result_color}
                            </p>
                          </li>
                          <li>
                            <p>Select</p>
                            <p
                              style={{
                                fontFamily: "Arimo-Medium",
                                color:
                                  item.bet_no == 1 ||
                                    item.bet_no == 3 ||
                                    item.bet_no == 7 ||
                                    item.bet_no == 9 ||
                                    item.bet_no == 5
                                    ? "green"
                                    : item.bet_color == "voilet"
                                      ? "#9C27B0"
                                      : item.bet_color == "red"
                                        ? "red"
                                        : item.bet_color == "green"
                                          ? "green"
                                          : "red",
                                fontSize: 18,
                              }}
                            >
                              {item.bet_no == 1 ||
                                item.bet_no == 3 ||
                                item.bet_no == 7 ||
                                item.bet_no == 9
                                ? ` ${item.bet_no} green`
                                : item.bet_no == 2 ||
                                  item.bet_no == 4 ||
                                  item.bet_no == 6 ||
                                  item.bet_no == 8
                                  ? ` ${item.bet_no} red`
                                  : item.bet_no == 0
                                    ? `${item.bet_no} red, voilet `
                                    : item.bet_no == 5
                                      ? `${item.bet_no} green, voilet`
                                      : `${item.bet_color} `}
                            </p>
                          </li>
                          <li>
                            <p>Status</p>
                            <p>{item.status}</p>
                          </li>
                          <li>
                            <p>Amount</p>
                            <p>{item.amount_earn}</p>
                          </li>
                          <li>
                            <p>Create Time</p>
                            <p>{item.created_at}</p>
                          </li>
                          <li>
                            <p>Type</p>
                            <p>{item.game_type}</p>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : topbar === 1 ? (
          <>
            <div className="content">
              <div className="content-con">
                <div className="My-Order">
                  {data.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <ul
                        onClick={() => {
                          favHandler(index);
                          setisopen(!isopen);
                        }}
                        style={{ width: "100%", margin: 5 }}
                      >
                        <li
                          style={{
                            width: "80%",
                            justifyContent: "space-between",
                            padding: 2,
                          }}
                        >
                          <ol style={{ width: "40%" }}>
                            {item.game_type === "sapre"
                              ? "S"
                              : item.game_type === "emeral"
                                ? "E"
                                : ""}
                            {item.game_id}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "Green"
                                    : item.status == "wait"
                                      ? "white"
                                      : "",
                              width: "40%",
                            }}
                          >
                            {item.status}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "green"
                                    : "",
                              width: "20%",
                            }}
                          >
                            {item.amount_earn}
                          </ol>
                        </li>
                        <li>
                          {item.isselected === true ? (
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              color={"#808080"}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              color={"#808080"}
                            />
                          )}
                        </li>
                      </ul>
                      {item.isselected === true ? (
                        <ul className="drop">
                          <li>
                            <p>Period </p>
                            <p>{item.game_id}</p>
                          </li>
                          <li>
                            <p>Contract Money</p>
                            <p>{item.bet_amount}</p>
                          </li>
                          <li>
                            <p>Delivery </p>
                            <p
                              style={{
                                color:
                                  item.status === "Lost"
                                    ? "red"
                                    : item.status === "Win"
                                      ? "green"
                                      : "",
                              }}
                            >
                              {item.amount_earn}
                            </p>
                          </li>
                          <li>
                            <p>Fee</p>
                            <p>{item.fees}</p>
                          </li>
                          <li>
                            <p>Result</p>
                            <p>
                              {item.result_num} {item.result_color}
                            </p>
                          </li>
                          <li>
                            <p>Select</p>
                            <p
                              style={{
                                fontFamily: "Arimo-Medium",
                                color:
                                  item.bet_no == 1 ||
                                    item.bet_no == 3 ||
                                    item.bet_no == 7 ||
                                    item.bet_no == 9 ||
                                    item.bet_no == 5
                                    ? "green"
                                    : item.bet_color == "voilet"
                                      ? "#9C27B0"
                                      : item.bet_color == "red"
                                        ? "red"
                                        : item.bet_color == "green"
                                          ? "green"
                                          : "red",
                                fontSize: 18,
                              }}
                            >
                              {item.bet_no == 1 ||
                                item.bet_no == 3 ||
                                item.bet_no == 7 ||
                                item.bet_no == 9
                                ? ` ${item.bet_no} green`
                                : item.bet_no == 2 ||
                                  item.bet_no == 4 ||
                                  item.bet_no == 6 ||
                                  item.bet_no == 8
                                  ? ` ${item.bet_no} red`
                                  : item.bet_no == 0
                                    ? `${item.bet_no} red, voilet `
                                    : item.bet_no == 5
                                      ? `${item.bet_no} green, voilet`
                                      : `${item.bet_color} `}
                            </p>
                          </li>
                          <li>
                            <p>Status</p>
                            <p>{item.status}</p>
                          </li>
                          <li>
                            <p>Amount</p>
                            <p>{item.amount_earn}</p>
                          </li>
                          <li>
                            <p>Create Time</p>
                            <p>{item.created_at}</p>
                          </li>
                          <li>
                            <p>Type</p>
                            <p>{item.game_type}</p>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : topbar === 2 ? (
          <>
            <div className="content">
              <div className="content-con">
                <div className="My-Order">
                  {data.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <ul
                        onClick={() => {
                          favHandler(index);
                          setisopen(!isopen);
                        }}
                        style={{ width: "100%", margin: 5 }}
                      >
                        <li
                          style={{
                            width: "80%",
                            justifyContent: "space-between",
                            padding: 2,
                          }}
                        >
                          <ol style={{ width: "40%" }}>
                            {item.game_type === "sapre"
                              ? "S"
                              : item.game_type === "emeral"
                                ? "E"
                                : ""}
                            {item.game_id}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "Green"
                                    : item.status == "wait"
                                      ? "white"
                                      : "",
                              width: "40%",
                            }}
                          >
                            {item.status}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "green"
                                    : "",

                              width: "20%",
                            }}
                          >
                            {item.amount_earn}
                          </ol>
                        </li>
                        <li>
                          {item.isselected === true ? (
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              color={"#808080"}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              color={"#808080"}
                            />
                          )}
                        </li>
                      </ul>
                      {item.isselected === true ? (
                        <ul className="drop">
                          <li>
                            <p>Period </p>
                            <p>{item.game_id}</p>
                          </li>
                          <li>
                            <p>Contract Money</p>
                            <p>{item.bet_amount}</p>
                          </li>
                          <li>
                            <p>Delivery </p>
                            <p
                              style={{
                                color:
                                  item.status === "Lost"
                                    ? "red"
                                    : item.status === "Win"
                                      ? "green"
                                      : "",
                              }}
                            >
                              {item.amount_earn}
                            </p>
                          </li>
                          <li>
                            <p>Fee</p>
                            <p>{item.fees}</p>
                          </li>
                          <li>
                            <p>Result</p>
                            <p>
                              {item.result_num} {item.result_color}
                            </p>
                          </li>
                          <li>
                            <p>Select</p>
                            <p
                              style={{
                                fontFamily: "Arimo-Medium",
                                color:
                                  item.bet_no == 1 ||
                                    item.bet_no == 3 ||
                                    item.bet_no == 7 ||
                                    item.bet_no == 9 ||
                                    item.bet_no == 5
                                    ? "green"
                                    : item.bet_color == "voilet"
                                      ? "#9C27B0"
                                      : item.bet_color == "red"
                                        ? "red"
                                        : item.bet_color == "green"
                                          ? "green"
                                          : "red",
                                fontSize: 18,
                              }}
                            >
                              {item.bet_no == 1 ||
                                item.bet_no == 3 ||
                                item.bet_no == 7 ||
                                item.bet_no == 9
                                ? ` ${item.bet_no} green`
                                : item.bet_no == 2 ||
                                  item.bet_no == 4 ||
                                  item.bet_no == 6 ||
                                  item.bet_no == 8
                                  ? ` ${item.bet_no} red`
                                  : item.bet_no == 0
                                    ? `${item.bet_no} red, voilet `
                                    : item.bet_no == 5
                                      ? `${item.bet_no} green, voilet`
                                      : `${item.bet_color} `}
                            </p>
                          </li>
                          <li>
                            <p>Status</p>
                            <p>{item.status}</p>
                          </li>
                          <li>
                            <p>Amount</p>
                            <p>{item.amount_earn}</p>
                          </li>
                          <li>
                            <p>Create Time</p>
                            <p>{item.created_at}</p>
                          </li>
                          <li>
                            <p>Type</p>
                            <p>{item.game_type}</p>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : topbar === 3 ? (
          <>
            <div className="content">
              <div className="content-con">
                <div className="My-Order">
                  {data.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <ul
                        onClick={() => {
                          favHandler(index);
                          setisopen(!isopen);
                        }}
                        style={{ width: "100%", margin: 5 }}
                      >
                        <li
                          style={{
                            width: "80%",
                            justifyContent: "space-between",
                            padding: 2,
                          }}
                        >
                          <ol style={{ width: "40%" }}>
                            {item.game_type === "sapre"
                              ? "S"
                              : item.game_type === "emeral"
                                ? "E"
                                : ""}
                            {item.game_id}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "Green"
                                    : item.status == "wait"
                                      ? "white"
                                      : "",
                              width: "40%",
                            }}
                          >
                            {item.status}
                          </ol>
                          <ol
                            style={{
                              color:
                                item.status == "Lost"
                                  ? "red"
                                  : item.status == "Win"
                                    ? "green"
                                    : "",
                              width: "20%",
                            }}
                          >
                            {item.amount_earn}
                          </ol>
                        </li>
                        <li>
                          {item.isselected === true ? (
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              color={"#808080"}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              color={"#808080"}
                            />
                          )}
                        </li>
                      </ul>
                      {item.isselected === true ? (
                        <ul className="drop">
                          <li>
                            <p>Period </p>
                            <p>{item.game_id}</p>
                          </li>
                          <li>
                            <p>Contract Money</p>
                            <p>{item.bet_amount}</p>
                          </li>
                          <li>
                            <p>Delivery </p>
                            <p
                              style={{
                                color:
                                  item.status === "Lost"
                                    ? "red"
                                    : item.status === "Win"
                                      ? "green"
                                      : "",
                              }}
                            >
                              {item.amount_earn}
                            </p>
                          </li>
                          <li>
                            <p>Fee</p>
                            <p>{item.fees}</p>
                          </li>
                          <li>
                            <p>Result</p>
                            <p>
                              {item.result_num} {item.result_color}
                            </p>
                          </li>
                          <li>
                            <p>Select</p>
                            <p
                              style={{
                                fontFamily: "Arimo-Medium",
                                color:
                                  item.bet_no == 1 ||
                                    item.bet_no == 3 ||
                                    item.bet_no == 7 ||
                                    item.bet_no == 9 ||
                                    item.bet_no == 5
                                    ? "green"
                                    : item.bet_color == "voilet"
                                      ? "#9C27B0"
                                      : item.bet_color == "red"
                                        ? "red"
                                        : item.bet_color == "green"
                                          ? "green"
                                          : "red",
                                fontSize: 18,
                              }}
                            >
                              {item.bet_no == 1 ||
                                item.bet_no == 3 ||
                                item.bet_no == 7 ||
                                item.bet_no == 9
                                ? ` ${item.bet_no} green`
                                : item.bet_no == 2 ||
                                  item.bet_no == 4 ||
                                  item.bet_no == 6 ||
                                  item.bet_no == 8
                                  ? ` ${item.bet_no} red`
                                  : item.bet_no == 0
                                    ? `${item.bet_no} red, voilet `
                                    : item.bet_no == 5
                                      ? `${item.bet_no} green, voilet`
                                      : `${item.bet_color} `}
                            </p>
                          </li>
                          <li>
                            <p>Status</p>
                            <p>{item.status}</p>
                          </li>
                          <li>
                            <p>Amount</p>
                            <p>{item.amount_earn}</p>
                          </li>
                          <li>
                            <p>Create Time</p>
                            <p>{item.created_at}</p>
                          </li>
                          <li>
                            <p>Type</p>
                            <p>{item.game_type}</p>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home1">
              <FontAwesomeIcon icon={faHouse} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>Home</span>
          </li>

          <li>
            <Link to="/Search1">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Win">
              <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
            </Link>
            <span>Win</span>
          </li>

          <li>
            <Link to="/My1" className="link">
              <FontAwesomeIcon icon={faUser} color={"#808080"} />
            </Link>
            <span>My</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Myorder;
