import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faHouse, faUser, faMagnifyingGlass, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Margin } from '@mui/icons-material';

function Privacypolicy() {
    const [privacypolicy, setprivacypolicy] = useState()

    const PrivacyPolicy = () => {
        const options = {
            method: 'GET', url: 'https://api.wazir99.com/api/privacyPolicy'
        };
        axios.request(options).then(function (response) {
            console.log(response.data.data.content);
            setprivacypolicy(response.data.data.content)
        }).catch(function (error) {
            console.error(error);
        });
    }


    useEffect(() => {
        PrivacyPolicy()
    }, [])



    return (
        <div className='fullscreen'>
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>Privacy Policy</span>
                </div>
            </nav>
            <div>
                <p style={{ margin: '1%' }}>{privacypolicy}</p>
            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home1'  >
                            <FontAwesomeIcon icon={faHouse} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search1'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Win</span>
                    </li>

                    <li>
                        <Link to='/My1' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#009688'} />
                        </Link>
                        <span style={{
                            color: '#009688'
                        }}>My</span>
                    </li>

                </ul>
            </div>
        </div>

    )
}

export default Privacypolicy