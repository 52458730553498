import "@fortawesome/fontawesome-svg-core/styles.css";
import React, { useState } from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMobileScreenButton,
  faKey,
  faHouse,
  faUser,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import My1 from '../AfterLogin/My1';
// import { useHistory } from 'react-router'; // Import useHistory from react-router

function Login() {
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  // const history = useHistory(); // Access the history object

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    console.log({ number, password });
    const options = {
      method: "POST",
      url: "https://api.wazir99.com/api/login",
      data: { mobile: number, password: password },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 0) {
          toast(response.data.msg);

        }
        if (response.data.status === 1) {
          navigate('/My1');
        }
        localStorage.setItem("userid", response.data.data.userid);
        // Handle other cases if needed
      })
      .catch(function (error) {
        console.error(error);
        // Handle login error here
      });
  };

  return (
    <div className="fullscreen">
      <div className="Header">
        <div className="elements">
          <FontAwesomeIcon
            icon={faArrowLeft}
            color={"#fafafa"}
            style={{ marginRight: "30px", marginLeft: "15px" }}
          />
          <h5>Login</h5>
        </div>
      </div>
      <div className="form">
        <div className="inputfield">
          <div id="number">
            <FontAwesomeIcon icon={faMobileScreenButton} color={"#808080"} />
            <input
              value={number}
              onChange={handleNumberChange}
              placeholder="Mobile Number"
              type="text"
              maxLength={10}
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            ></input>
          </div>
          <div id="password">
            <FontAwesomeIcon icon={faKey} color={"#808080"} />
            <input
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              type="password"
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            ></input>
          </div>
        </div>
        <div className="buttons">
          <button id="login" onClick={() => {
            handleLogin();
          }}>
            Login
          </button>
          <ToastContainer />
          <div className="btn">
            <Link to="/Register">
              <button id="register">Register</button>
            </Link>
            <Link to="/forgotpassword">
              <button id="ForgotPassword">Forgot Password?</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home">
              <FontAwesomeIcon icon={faHouse} color={"#808080"} />
            </Link>
            <span>Home</span>
          </li>

          <li>
            <Link to="/Search">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Login" className="link">
              <FontAwesomeIcon icon={faUser} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>My</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Login;
