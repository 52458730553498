import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faTrophy, faHouse, faUser, faMagnifyingGlass, faArrowLeft } from '@fortawesome/free-solid-svg-icons';


function AddBankcard() {
    return (
        <div className="AddBankcard">
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>AddBankcard</span>
                </div>
            </nav>

            <div className="input-card">
                <ul className="card-ul">
                    <li>
                        <p>Actual Name</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>IFSC Code</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Bank Name</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Bank Account</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>State/Territory</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>City</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Address</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Mobile Number</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Email</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Account phone number</p>
                        <input type="text" />
                    </li>
                    <li>
                        <p>Code</p>
                        <div style={{ display: "flex" }}>
                            <input type="text" placeholder="Verification Code" style={{ flex: "1 1 0 %", width: '100%' }} />
                            <button className="go-code"> OTP </button>
                        </div>
                    </li>
                </ul>
                <div className="continue-btn">
                    <button>Continue</button>
                </div>
            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home1'  >
                            <FontAwesomeIcon icon={faHouse} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search1'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span>Win</span>
                    </li>

                    <li>
                        <Link to='/My1' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#009688'} />
                        </Link>
                        <span style={{
                            color: '#009688'
                        }}>My</span>
                    </li>

                </ul>
            </div>

        </div>
    );
};

export default AddBankcard;