import React, { useState } from "react";
import Countdown from 'react-countdown';



function Cell({ content }) {
    return (
        <div className="cell border">
            <div className="item-green">{content}</div>
        </div>
    );
}

function Trend() {
    const [isButton1Active, setIsButton1Active] = useState(true);
    const rgbColor = "rgb(232, 57, 241)";

    const handleButtonClick = () => {
        setIsButton1Active((prev) => !prev);
    };

    const cellDataColumns = Array.from({ length: 10 }, (_, index) => index.toString());

    // Create the cellData array for rows (1 to 11)
    const cellDataRows = Array.from({ length: 11 }, (_, index) => (index + 1).toString());

    const rowsPerColumn = 12; // For example, 12 rows per column
    const numberOfColumns = Math.ceil(cellDataColumns.length / rowsPerColumn);

    const columns = Array.from({ length: numberOfColumns }, (_, columnIndex) =>
        cellDataColumns.slice(columnIndex * rowsPerColumn, (columnIndex + 1) * rowsPerColumn)
    );

    return (
        <div id="app">
            <div className="layout-content">
                <div className="parity-content">
                    <div className="van-nav-bar">
                        <div className="van-nav-bar-left"></div>
                        <div className="van-nav-bar-center van-ellipsis">Spare Record</div>
                        <div className="van-nav-bar-right"></div>
                    </div>
                    <div>
                        <div className="kline">
                            <div className="reservation-chunk">
                                <div className="reservation-chunk-sub">
                                    <div className="reservation-chunk-sub-title">Period</div>
                                    <div className="reservation-chunk-sub-num">20230726340</div>
                                </div>
                                <div className="reservation-chunk-sub" style={{ textAlign: "right" }}>
                                    <div className="reservation-chunk-sub-title">Count Down</div>
                                    <div className="reservation-chunk-sub-num" >
                                        <Countdown date={Date.now() + 180000} ></Countdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kline">
                            <div className="reservation-chunk">
                                <div className="reservation-chunk-sub">
                                    <div className="reservation-chunk-sub-title">Pre Period</div>
                                    <div className="reservation-chunk-sub-num">20230726342</div>
                                </div>
                                <div className="reservation-chunk-sub" style={{ textAlign: 'center' }}>
                                    <div className="reservation-chunk-sub-title">Result</div>
                                    <div className="reservation-chunk-sub-num">
                                        <div className="item-green" style={{ margin: "5px 0px 0px 35px" }}></div>
                                    </div>
                                </div>
                                <div className="reservation-chunk-sub" style={{ textAlign: "right" }}>
                                    <div className="reservation-chunk-sub-title">Open-Price</div>
                                    <div className="reservation-chunk-sub-num">
                                        <div style={{ color: rgbColor }}>29866</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kline">
                            <div className="title">
                                <div className="red">Red : 183</div>
                                <div className="green">Green : 164</div>
                                <div className="voilet">Violet : 33</div>
                            </div>
                            <div className="switchbox">

                                <button
                                    style={{
                                        backgroundColor: isButton1Active ? "#07c160" : "#fff",
                                        color: isButton1Active ? "#fff" : "black",
                                        border: isButton1Active ? "#07c160" : "black",
                                        margin: 4

                                    }}
                                    onClick={handleButtonClick}
                                    className="van-button van-button-small"
                                >
                                    Show Period
                                </button>
                                <button
                                    style={{
                                        backgroundColor: !isButton1Active ? "#07c160" : "transparent",
                                        color: !isButton1Active ? "#fff" : "#323233",
                                        border: !isButton1Active ? "#07c160" : "balck"
                                    }}
                                    onClick={handleButtonClick}
                                    className="van-button van-button-small"
                                >
                                    Show OpenNum
                                </button>

                            </div>
                            <div className="box">
                                <div className="grid">
                                    {columns.map((column, columnIndex) => (
                                        <div className="column" key={columnIndex}>
                                            <div className="row">
                                                <div className="index">1</div>
                                                {cellDataRows.map((rowContent, rowIndex) => (
                                                    <Cell content={column[rowIndex]} />
                                                ))}
                                            </div>

                                        </div>
                                    ))}
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                    <div className="row"></div>
                                </div>
                            </div>
                        </div>
                        <div className="kline"></div>
                        <div className="kline"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Trend;