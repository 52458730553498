import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRectangleList,
  faHouse,
  faUser,
  faMagnifyingGlass,
  faTrophy,
  faWallet,
  faBuildingColumns,
  faInfo,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../Images/2.jpeg"
import profile from "../Images/profile.png"
// import Modal from "react-modal";

function My1() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [id, setid] = useState();
  const [balance, setbalance] = useState();

  const ProfileHandler = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "GET",
      url: `https://api.wazir99.com/api/profileDetail/${userId}`,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.user.wallet_bal);
        setName(response.data.data.user.name);
        setid(response.data.data.user.id);
        setbalance(response.data.data.user.wallet_bal);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const xyz = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    return userId; // Optionally, you can return the value for further use.
  };
  const logOuthandle = () => {
    localStorage.removeItem("userid");
    navigate("/Login");
  };

  // const openModal = () => {
  //     setModalIsOpen(true);
  // };

  // const closeModal = () => {
  //     setModalIsOpen(false);
  // };

  // const handleSignIn = () => {
  //     setSignedIn(true);
  // };

  useEffect(() => {
    ProfileHandler();
  }, []);

  return (
    <div className="my">
      <div className="my-top">
        <div className="my-info">
          <div className="my-info-top">
            <div className="info-left">
              <img alt="" src={logo} />
              <ul>
                <li>User：{name}</li>
                <li>ID：{id}</li>
              </ul>
            </div>
            <div className="info-right">
              <div className="notice">
                <img alt="" src={profile} />
              </div>
            </div>
          </div>
          <div className="my-info-items">
            <div className="top-item">
              <div>₹ {balance}</div>
              Balance
              <button
                className="one-btn rpl"
                onClick={() => {
                  navigate("/Recharge");
                }}
              >
                Recharge
              </button>
            </div>
            {/* <div className="top-item">
                            <div>₹ 0</div>
                            Commission
                            <button className="one-btn rpl">See</button>
                        </div>
                        <div className="top-item">
                            <div>₹ 1.47</div>
                            Interest
                            <button className="one-btn rpl">See</button>
                        </div> */}
          </div>
        </div>
      </div>
      <div className="my-nav">
        <ul>
          {/* <li onClick={openModal}>
                        <ol>
                            <FontAwesomeIcon icon={faCalendarCheck} color={'#808080'} className="icon" />
                            <span>Sign In</span>
                        </ol>
                        <ol >
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                            ><div className="ttl"><h2>Sign In</h2>
                                </div>
                                <div className="sign-in-info">
                                    <p className="sign-info">Total：2 Days</p>
                                    <p className="sign-info">Today Rebates：₹ 0</p>
                                    <p className="sign-info">Total Rebates：₹ 0</p>
                                    {signedIn ? (
                                        <p className="sign-info">Status：Had signed in</p>
                                    ) : (
                                        <p className="sign-info">Status：No sign in</p>
                                    )}
                                </div>
                                <div className="btn-close">
                                    <button onClick={closeModal} className="cancel">CANCEL</button>
                                    <button onClick={handleSignIn} >SIGN IN</button>
                                </div>
                            </Modal>
                        </ol>

                    </li> */}
          <li
            onClick={() => {
              navigate("/Myorder");
            }}
          >
            <ol>
              <FontAwesomeIcon
                icon={faRectangleList}
                color={"#808080"}
                className="icon"
              />
              <span>Orders</span>
            </ol>
            <ol>
              <FontAwesomeIcon icon={faChevronDown} color={"#808080"} />
            </ol>
          </li>
          {/* <li onClick={redirectToPromotion}>
                        <ol>
                            <FontAwesomeIcon icon={faGift} color={'#808080'} className="icon" />
                            <span>Promotion</span>
                        </ol>
                        <ol>
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                        </ol>

                    </li>
                    <li onClick={redirectToRedenvelope}>
                        <ol>
                            <FontAwesomeIcon icon={faSackDollar} color={'#009688'} className="icon" />
                            <span>RedEnvelope</span>
                        </ol>
                        <ol>
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                        </ol>

                    </li> */}
          <li
            onClick={() => {
              navigate("/Recharge");
            }}
          >
            <ol>
              <FontAwesomeIcon
                icon={faWallet}
                color={"#808080"}
                className="icon"
              />
              <span>Deposit</span>
            </ol>
            <ol>
              <FontAwesomeIcon icon={faChevronDown} color={"#808080"} />
            </ol>
          </li>
          <li
            onClick={() => {
              navigate("/Withdrawal");
            }}
          >
            <ol>
              <FontAwesomeIcon
                icon={faWallet}
                color={"#808080"}
                className="icon"
              />
              <span>Withdrawal</span>
            </ol>
            <ol>
              <FontAwesomeIcon icon={faChevronDown} color={"#808080"} />
            </ol>
          </li>
          <li
            onClick={() => {
              navigate("/Accountdetails");
            }}
          >
            <ol>
              <FontAwesomeIcon
                icon={faBuildingColumns}
                color={"#808080"}
                className="icon"
              />
              <span>Account Details</span>
            </ol>
            <ol>
              <FontAwesomeIcon icon={faChevronDown} color={"#808080"} />
            </ol>
          </li>
          <li
            onClick={() => {
              navigate("/PrivacyPolicy");
            }}
          >
            <ol>
              <FontAwesomeIcon
                icon={faInfo}
                color={"#808080"}
                className="icon"
              />
              <span>Privacy Policy</span>
            </ol>
            <ol>
              <FontAwesomeIcon icon={faChevronDown} color={"#808080"} />
            </ol>
          </li>
          {/* <li onClick={redirectToAddress}>
                        <ol>
                            <FontAwesomeIcon icon={faBuilding} color={'#808080'} className="icon" />
                            <span>Address</span>
                        </ol>
                        <ol>
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                        </ol>

                    </li>
                    <li>
                        <ol>
                            <FontAwesomeIcon icon={faShieldHalved} color={'#808080'} className="icon" />
                            <span>Account Security</span>
                        </ol>
                        <ol>
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                        </ol>

                    </li>
                    <li>
                        <ol>
                            <FontAwesomeIcon icon={faDownload} color={'#808080'} className="icon" />
                            <span>App Download</span>
                        </ol>
                        <ol>
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                        </ol>

                    </li>
                    <li onClick={redirectToComplaint}>
                        <ol>
                            <FontAwesomeIcon icon={faMessage} color={'#808080'} className="icon" />
                            <span>Complaints & Suggestions</span>
                        </ol>
                        <ol>
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                        </ol>

                    </li>
                    <li>
                        <ol>
                            <FontAwesomeIcon icon={faCircleInfo} color={'#808080'} className="icon" />
                            <span>About</span>
                        </ol>
                        <ol>
                            <FontAwesomeIcon icon={faChevronDown} color={'#808080'} />
                        </ol>

                    </li> */}
        </ul>
      </div>
      <div className="logout">
        <button className="rpl" onClick={logOuthandle}>
          Logout
        </button>
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home1">
              <FontAwesomeIcon icon={faHouse} color={"#808080"} />
            </Link>
            <span style={{ color: "#808080" }}>Home</span>
          </li>

          <li>
            <Link to="/Search1">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Win">
              <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
            </Link>
            <span>Win</span>
          </li>

          <li>
            <Link to="/My1" className="link">
              <FontAwesomeIcon icon={faUser} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>My</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default My1;
