import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faHouse, faUser, faMagnifyingGlass, faArrowLeft, faPlus, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';




function Redenvelope() {
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Assuming 10 rows per page as default
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        // Simulating fetching totalRows from API
        // For example, you can replace this with an actual API call to get the total number of rows
        setTotalRows(20); // Assuming there are 50 rows in total
    }, []);


    const totalPages = Math.ceil(totalRows / rowsPerPage);

    // Update the rowsPerPage when the dropdown value changes
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value));
    };



    return (
        <div className="fullscreen">
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>RedEnvelope</span>
                </div>
                <div className="right">
                    <FontAwesomeIcon icon={faPlus} className="bar" />
                </div>
            </nav>
            <div className="red-box">
                <p className="null-data">No data available</p>
                <div className="pagination">
                    <ul className="page-box">
                        <li className="page">
                            <span>{(currentPage - 1) * rowsPerPage + 1}-{Math.min(currentPage * rowsPerPage, totalRows)} of {totalRows}</span>
                        </li>
                        <li className="page-btn">
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <FontAwesomeIcon icon={faChevronRight} />
                        </li>
                    </ul>
                </div>
                <div className="choose-page">
                    <div className="choose-page-par">
                        <span>Rows per page:</span>
                        <div className="page-box-two">
                            <div className="van-dropdown-menu">
                                <div className="van-dropdown-menu-bar">
                                    <div className="van-dropdown-menu-item">
                                        <select value={rowsPerPage} onChange={handleRowsPerPageChange} className="van-dropdown-menu-title" >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            {/* Add more options based on your requirement */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home'  >
                            <FontAwesomeIcon icon={faHouse} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span>Win</span>
                    </li>

                    <li>
                        <Link to='/' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#009688'} />
                        </Link>
                        <span style={{
                            color: '#009688'
                        }}>My</span>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default Redenvelope;