import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faArrowLeft, faWallet, faCheck, faKey, faHouse, faMagnifyingGlass, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import AddBankcard from "./AddBankcard";

function Withdrawal() {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [withdrawalbalance, setwithdrawalbalance] = useState();
    const [transactionList, settransactionList] = useState([]);
    const [requestwithdraw, setrequestwithdraw] = useState()
    // console.log(
    //     requestwithdraw
    // );
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const WithdrawalListHandler = () => {
        const userId = localStorage.getItem('userid');
        console.log(userId);
        const options = {
            method: 'POST',
            url: 'https://api.wazir99.com/api/request/withdrawal',
            data: {
                user_id: `${userId}`,
                amount: requestwithdraw
            }
        };

        axios.request(options).then(function (response) {
            console.log(response.data);
            WithdrawalHandler()
            if (response.data.status === 1) {
                toast(response.data.msg)
            }
            if (response.data.status === 0) {
                toast(response.data.msg)
            }
        }).catch(function (error) {
            console.error(error);
        });
    }

    const WithdrawalHandler = () => {
        const userId = localStorage.getItem('userid');
        console.log(userId);
        const options = {
            method: 'GET',
            url: `https://api.wazir99.com/api/withdrawal/list/${userId}`
        };

        axios.request(options).then(function (response) {
            console.log(response.data.data.list);
            setwithdrawalbalance(response.data.data.wallet_bal)
            settransactionList(response.data.data.list)
        }).catch(function (error) {
            console.error(error);
        });
    }



    const options = ["State bank of india 310*****109"];

    // const transactionLogs = [
    //     { request: "Request Withdrawal", amount: 100, status: "pending", date: "2023-07-29" },
    //     { request: "Request Withdrawal", amount: 5000, status: "pending", date: "2023-07-29" },
    //     { request: "Request Withdrawal", amount: 10000, status: "pending", date: "2023-07-29" },
    // ];



    useEffect(() => {
        WithdrawalHandler()
    }, [])

    return (
        <div className="fullscreen">
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>Withdrawal</span>
                </div>
            </nav>
            <div className="recharge-box">
                <p className="top-txt">
                    Balance: ₹
                    <span>{withdrawalbalance}</span>
                </p>
                <div className="code-input-box">
                    <div className="code-input">
                        <FontAwesomeIcon icon={faWallet} color={'#808080'} className="icon" />
                        <input placeholder="Enter Withdrawal amount" type="text" onChange={(e) => setrequestwithdraw(e.target.value)} />
                    </div>
                </div>
                <div className="text-field">
                    <p>
                        Fee:
                        <span>0</span>
                        , to account
                        <span> 0</span>
                    </p>
                </div>
                <div className="payment-box-w">
                    <p className="payment-text">Payout</p>
                    <div className="van-radio-group">
                        <div className="van-radio" >
                            <div className="van-icon">
                                <FontAwesomeIcon icon={faCheck} />
                            </div>
                            <span className="van-level">
                                <span className="text">Bankcard</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="add-card">
                    <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
                        <div className="selected-option">
                            {selectedOption ? selectedOption : "Select Bank Card"}
                        </div>
                        {isOpen && (
                            <ul className="options-list">
                                {options.map((option, index) => (
                                    <li key={index} onClick={() => handleOptionClick(option)}>
                                        {option}
                                    </li>
                                ))}
                                <Link to="/AddBankcard" className="decor" >
                                    <li>Add Bank Card</li>
                                </Link>
                            </ul>

                        )}
                    </div>
                </div>
                <ToastContainer />
                <div className="password-box">
                    <div className="box-border">
                        <FontAwesomeIcon icon={faKey} color={"grey"} />
                        <input type="password" placeholder="Enter Your Login Password"></input>
                    </div>
                </div>
                <div className="recharge-btn">
                    <button onClick={() => { WithdrawalListHandler() }}>Withdrawal</button>
                </div>
                <div className="transaction">
                    <h2>TRANSACTION LOGS</h2>
                    <ul className="history">
                        {transactionList.map((item, index) => (
                            <li key={index} >
                                <p>Request Withdrawal</p>
                                <p>{item.tranfer_status}</p>
                                <p> ₹ {item.amount}</p>
                                <p>{item.requested_on}</p>
                            </li>
                        ))}
                    </ul>
                </div>


            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home1'  >
                            <FontAwesomeIcon icon={faHouse} color={'#009688'} />
                        </Link>
                        <span style={{ color: '#009688' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search1'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span>Win</span>
                    </li>


                    <li>
                        <Link to='/My1' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#808080'} />
                        </Link>
                        <span>My</span>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default Withdrawal;