import React, { useState, useCallback, useFocusEffect, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophy,
  faHouse,
  faUser,
  faMagnifyingGlass,
  faArrowLeft,
  faWallet,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Recharge() {
  const [rechargeAmount, setRechargeAmount] = useState("");
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [walletbalance, setwalletbalance] = useState();
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [transactionList, setTransaction] = useState([]);
  const [paymentId, setPaymentId] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [sign, setsign] = useState([]);
  const [paymentstatus, setPaymentstatus] = [];
  // Initialize as an empty array
  // console.log("sdcdsdsjnjnjn", rechargeAmount);

  const RechargeHandler = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "GET",
      url: `https://api.wazir99.com/api/walletHistory/${userId}`,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.data.payment_status);
        setwalletbalance(response.data.data.wallet_bal);
        setTransaction(response.data.data.list); // Update the state with an array of transaction data
        setPaymentId(response.data.data.payment_id);
        setOrderId(response.data.data.order_id);
        setsign(response.data.data.signature);
        setPaymentstatus(response.data.data.payment_status);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const PaymentHandler = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "POST",
      url: "https://api.wazir99.com/api/updateWalletTxn",
      headers: { Authorization: "Bearer " },
      data: {
        userid: `${userId}`,
        amount: rechargeAmount,
        payment_id: "paymentId",
        order_id: "orderId",
        payment_status: "success",
        signature: "sign",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        RechargeHandler();
        if (response.data.status === 1) {
          toast(response.data.msg)
        }
        if (response.data.status === 0) {
          toast(response.data.msg)
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  // const handleRecharge = () => {
  //     const rechargeStatus = "success";

  //     setTransactionStatus(rechargeStatus);
  // };

  const transactionLogs = [
    {
      request: "Request Withdrawal",
      amount: 1000,
      status: "pending",
      date: "2023-07-29",
    },
    {
      request: "Request Withdrawal",
      amount: 100,
      status: "pending",
      date: "2023-07-29",
    },
    {
      request: "Request Withdrawal",
      amount: 5000,
      status: "pending",
      date: "2023-07-29",
    },
  ];

  const handleAmountButtonClick = (amount) => {
    setRechargeAmount(amount);
    setClickedButton(amount);
  };

  const handlePaymentOptionClick = (paymentOption) => {
    setSelectedPaymentOption(
      paymentOption === selectedPaymentOption ? null : paymentOption
    );
  };

  // useFocusEffect(
  //     useCallback(() => {
  //         RechargeHandler()
  //     }, [])
  // );
  useEffect(() => {
    RechargeHandler();
  }, []);

  return (
    <div className="fullscreen">
      <nav className="top-nav">
        <div className="left">
          <Link to="/My1">
            <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
          </Link>
          <span>Recharge</span>
        </div>
      </nav>
      <div className="recharge-box">
        <p className="top-txt">
          Balance: ₹<span>{walletbalance}</span>
        </p>
        <div className="code-input-box">
          <div className="code-input">
            <FontAwesomeIcon
              icon={faWallet}
              color={"#808080"}
              className="icon"
            />
            <input
              placeholder="Enter or Select recharge amount"
              type="text"
              value={rechargeAmount}
              onChange={(e) => setRechargeAmount(e.target.value)}
            />
          </div>
        </div>
        <div className="amount-list">
          <button
            onClick={() => handleAmountButtonClick("500")}
            style={{
              backgroundColor: clickedButton === "500" ? "#2196f3" : "",
              color: clickedButton === "500" ? "white" : "",
            }}
          >
            ₹ 500
          </button>
          <button
            onClick={() => handleAmountButtonClick("1000")}
            style={{
              backgroundColor: clickedButton === "1000" ? "#2196f3" : "",
              color: clickedButton === "1000" ? "white" : "",
            }}
          >
            ₹ 1000
          </button>
          <button
            onClick={() => handleAmountButtonClick("2000")}
            style={{
              backgroundColor: clickedButton === "2000" ? "#2196f3" : "",
              color: clickedButton === "2000" ? "white" : "",
            }}
          >
            ₹ 2000
          </button>
          <button
            onClick={() => handleAmountButtonClick("5000")}
            style={{
              backgroundColor: clickedButton === "5000" ? "#2196f3" : "",
              color: clickedButton === "5000" ? "white" : "",
            }}
          >
            ₹ 5000
          </button>
          <button
            onClick={() => handleAmountButtonClick("10000")}
            style={{
              backgroundColor: clickedButton === "10000" ? "#2196f3" : "",
              color: clickedButton === "10000" ? "white" : "",
            }}
          >
            ₹ 10000
          </button>
          <button
            onClick={() => handleAmountButtonClick("50000")}
            style={{
              backgroundColor: clickedButton === "50000" ? "#2196f3" : "",
              color: clickedButton === "50000" ? "white" : "",
            }}
          >
            ₹ 50000
          </button>
        </div>
        <div className="payment-box">
          <p className="payment-text">Payment</p>
          <div className="van-radio-group">
            <div
              className={`van-radio ${selectedPaymentOption === "CQPay" ? "selected" : ""
                }`}
              onClick={() => handlePaymentOptionClick("CQPay")}
            >
              <div className="van-icon">
                {selectedPaymentOption === "CQPay" && (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </div>
              <span className="van-level">
                <span className="text">CQPay</span>
              </span>
            </div>
          </div>
          {/* <div className="van-radio-group">
            <div
              className={`van-radio ${
                selectedPaymentOption === "FFPay" ? "selected" : ""
              }`}
              onClick={() => handlePaymentOptionClick("FFPay")}
            >
              <div className="van-icon">
                {selectedPaymentOption === "FFPay" && (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </div>
              <span className="van-level">
                <span className="text">FFPay</span>
              </span>
            </div>
          </div> */}
          {/* <div className="van-radio-group">
                        <div className={`van-radio ${selectedPaymentOption === "SepPay" ? "selected" : ""}`} onClick={() => handlePaymentOptionClick("SepPay")}>
                            <div className="van-icon">
                                {selectedPaymentOption === "SepPay" && <FontAwesomeIcon icon={faCheck} />}
                            </div>
                            <span className="van-level">
                                <span className="text">SepPay</span>
                            </span>
                        </div>
                    </div>
                    <div className="van-radio-group">
                        <div className={`van-radio ${selectedPaymentOption === "QEPay" ? "selected" : ""}`} onClick={() => handlePaymentOptionClick("QEPay")}>
                            <div className="van-icon">
                                {selectedPaymentOption === "QEPay" && <FontAwesomeIcon icon={faCheck} />}
                            </div>
                            <span className="van-level">
                                <span className="text">QEPay</span>
                            </span>
                        </div>
                    </div> */}
        </div>
        {/* <div className="recharge-btn">
                    <button>Recharge</button>
                </div> */}
        <ToastContainer />
        <div className="recharge-btn">
          <button
            onClick={() => {
              PaymentHandler();
            }}
          >
            Recharge
          </button>
        </div>
        {/* Show status message based on transactionStatus
                {transactionStatus === 'success' && (
                    <div className="transaction">
                        <p>Recharge Successful!</p>
                    </div>
                )}
                {transactionStatus === 'failed' && (
                    <div className="transaction">
                        <p>Recharge Failed. Please try again.</p>
                    </div>
                )}
                {transactionStatus === 'pending' && (
                    <div className="transaction">
                        <p>Recharge is pending. Please wait for confirmation.</p>
                    </div>
                )} */}
        <div className="transaction">
          <h2>TRANSACTION LOGS</h2>
          <ul className="history">
            {transactionList.map((item, index) => (
              <li key={index}>
                <p>Wallet Recharge</p>
                <p>{item.payment_status}</p>
                <p>₹ {item.amount}</p>
                <p>{item.payment_date}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home1">
              <FontAwesomeIcon icon={faHouse} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>Home</span>
          </li>

          <li>
            <Link to="/Search1">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Win">
              <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
            </Link>
            <span>Win</span>
          </li>

          <li>
            <Link to="/My1" className="link">
              <FontAwesomeIcon icon={faUser} color={"#808080"} />
            </Link>
            <span>My</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Recharge;
