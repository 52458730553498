import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Login/Login.js";
import Register from "./Login/Register";
import Forgotpassword from "./Login/Forgotpassword";
import Search from "./Tabs/Search";
import Home from "./Tabs/Home";
import My from "./Tabs/My";
import My1 from "./AfterLogin/My1";
import Myorder from "./AfterLogin/Myorder";
import Promotion from "./AfterLogin/Promotion";
import Redenvelope from "./AfterLogin/Redenvelope";
import Bankcard from "./AfterLogin/Bankcard";
import AddBankcard from "./AfterLogin/AddBankcard";
import Address from "./AfterLogin/Address";
import Addaddress from "./AfterLogin/Addaddress";
import Complaint from "./AfterLogin/Complaint";
import Win from "./AfterLogin/Win";
import Recharge from "./AfterLogin/Recharge";
import Trend from "./AfterLogin/Trend";
import Withdrawal from "./AfterLogin/Withdrawal";
import Home1 from "./AfterLogin/Home1";
import Search1 from "./AfterLogin/Search1";
import Product from "./AfterLogin/Product";
import Privacypolicy from "./AfterLogin/Privacypolicy";
import Accountdetails from "./AfterLogin/Accountdetails";
import Testing from "./AfterLogin/Testing";
import { useEffect, useState } from "react";
import Product1 from "./AfterLogin/Product1";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHouse, faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons';
// import { Link } from 'react-router-dom';

// import { Button } from 'react-bootstrap';

const App = () => {
  const [isInitialRouteSet, setIsInitialRouteSet] = useState(false);
  useEffect(() => {
    // Set the initial route
    setIsInitialRouteSet(true);
  }, []);
  return (
    <>
      <Routes>
        {isInitialRouteSet && <Route path="/" element={<Login />} />}
        <Route path="/My" Component={My} />
        <Route path="/Search" Component={Search} />
        <Route path="/Home" Component={Home} />
        <Route path="/Win" Component={Win} />
        <Route path="/Login" Component={Login} />
        <Route path="/Register" Component={Register} />
        <Route path="/Forgotpassword" Component={Forgotpassword} />
        <Route path="/My1" Component={My1} />
        <Route path="/Home1" Component={Home1} />
        <Route path="/Search1" Component={Search1} />
        <Route path="/Product/:id" Component={Product} />
        <Route path="/Product1/:id" Component={Product1} />
        <Route path="/Myorder" Component={Myorder} />
        <Route path="/Promotion" Component={Promotion} />
        <Route path="/Redenvelope" Component={Redenvelope} />
        <Route path="/Bankcard" Component={Bankcard} />
        <Route path="/AddBankcard" Component={AddBankcard} />
        <Route path="/Address" Component={Address} />
        <Route path="/Addaddress" Component={Addaddress} />
        <Route path="/Complaint" Component={Complaint} />
        <Route path="/Recharge" Component={Recharge} />
        <Route path="/Trend" Component={Trend} />
        <Route path="/Withdrawal" Component={Withdrawal} />
        <Route path="/Privacypolicy" Component={Privacypolicy} />
        <Route path="/Accountdetails" Component={Accountdetails} />
        <Route path="/Testing" Component={Testing} />
      </Routes>
    </>
  );
};

export default App;
