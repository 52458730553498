import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMagnifyingGlass,
  faHouse,
  faTrophy,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

function Accountdetails() {
  const [bankname, setbankname] = useState([]);
  const [name, setname] = useState([]);
  const [account, setaccount] = useState([]);
  const [ifsc, setifsc] = useState([]);

  const AccountHandler = () => {
    const userId = localStorage.getItem("userid");
    console.log(userId);
    const options = {
      method: "POST",
      url: "https://api.wazir99.com/api/addBank",
      data: {
        user_id: `${userId}`,
        bank_name: bankname,
        ac_holder: name,
        ac_no: account,
        ifsc: ifsc,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div className="fullscreen">
      <nav className="top-nav">
        <div className="left">
          <Link to="/My1">
            <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
          </Link>
          <span>Account Deatails</span>
        </div>
      </nav>
      <div className="input-card">
        <ul className="card-ul">
          <li>
            <p>Bank Name</p>
            <input
              type="text"
              onChange={(e) => {
                setbankname(e.target.value);
              }}
            />
          </li>
          <li>
            <p>Account Holder Name</p>
            <input
              type="text"
              onChange={(e) => {
                setname(e.target.value);
              }}
            />
          </li>
          <li>
            <p>Account Number</p>
            <input
              type="text"
              onChange={(e) => {
                setaccount(e.target.value);
              }}
            />
          </li>
          <li>
            <p>IFSC Code</p>
            <input
              type="text"
              onChange={(e) => {
                setifsc(e.target.value);
              }}
            />
          </li>
        </ul>
        <div className="continue-btn">
          <button
            onClick={() => {
              AccountHandler();
            }}
          >
            Update
          </button>
        </div>
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home1">
              <FontAwesomeIcon icon={faHouse} color={"#808080"} />
            </Link>
            <span style={{ color: "#808080" }}>Home</span>
          </li>

          <li>
            <Link to="/Search1">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Win">
              <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
            </Link>
            <span style={{ color: "#808080" }}>Win</span>
          </li>

          <li>
            <Link to="/My1" className="link">
              <FontAwesomeIcon icon={faUser} color={"#009688"} />
            </Link>
            <span
              style={{
                color: "#009688",
              }}
            >
              My
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Accountdetails;
