import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMobileScreenButton,
  faKey,
  faMessage,
  faHouse,
  faUser,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Forgotpassword() {
  return (
    <div className="fullscreen">
      <div className="Header">
        <div className="elements">
          <Link to="/">
            <FontAwesomeIcon
              icon={faArrowLeft}
              color={"#fafafa"}
              style={{ marginRight: "30px", marginLeft: "15px" }}
            />
          </Link>
          <h5>Reset Password</h5>
        </div>
      </div>
      <div className="form">
        <div className="inputfield">
          <div id="number">
            <FontAwesomeIcon icon={faMobileScreenButton} color={"#808080"} />
            <input
              placeholder="Mobile Number"
              type="text"
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            />
          </div>
          <div className="specialbox">
            <div className="inputbox" style={{ width: "70%" }}>
              <FontAwesomeIcon icon={faMessage} color={"#808080"} />
              <input
                placeholder="Verification Code"
                type="text"
                style={{ borderColor: "transparent", marginLeft: "1%" }}
              />
            </div>
            <button className="otpcode">OTP</button>
          </div>
          <div id="password">
            <FontAwesomeIcon icon={faKey} color={"#808080"} />
            <input
              placeholder="New Password"
              type="password"
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            ></input>
          </div>
          <div className="box_btn">
            <button className="regi_btn">Continue</button>
          </div>
        </div>
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="./Home">
              <FontAwesomeIcon icon={faHouse} color={"#808080"} />
            </Link>
            <span>Home</span>
          </li>

          <li>
            <Link to="/Search">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Login" className="link">
              <FontAwesomeIcon icon={faUser} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>My</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Forgotpassword;
