import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrophy, faHouse, faUser, faMagnifyingGlass, faBars, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Modal from "react-modal";




function Promotion() {
    const [successMessage, setSuccessMessage] = useState("");
    const [activeTab, setActiveTab] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(true);



    const closeModal = () => {
        setModalIsOpen(false);
    };


    const copyToClipboard = () => {
        const linkText = "https://red-bu11.app/#"; // Replace this with your actual promotion link
        navigator.clipboard.writeText(linkText)
            .then(() => {
                setSuccessMessage("Suscess");
            })
            .catch((error) => {
                console.error("Failed to copy link: ", error);
            });
    }
    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };
    return (
        <div className="promotion">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
            ><div className="ttl"><h6>Notice</h6>
                </div>
                <div className="sign-in-info">

                    <p className="sign-info">When your friends trade, you will also receive a 30% commission. Therefore, the more friends you invite, the higher your commission. There is a fixed income every day, the commission is permanent, but the reward is only onceWhen they make money, they will invite their friends to join them, and then you can get a 20% commission. In this way, your team can spread quickly. Therefore, I hope everyone can use our platform to make money, make money, and make money!When they make money, they will invite their friends to join them, and then you can get a 20% commission. In this way, your team can spread quickly. Therefore, I hope everyone can use our platform to make money, make money, and make money!Level 1 commission: Friends who join through your own link belong to your level, when they trade, you will get 30% commission.Tier 2 commission: Friends who join through your friend link belong to your secondary commission. When they trade, you can get 20% commission.Level 3 commission: Friends who join through friends of friends belong to your level 3. When they trade, you get 10% commission.Promotional rewards: 10% bonus amount for the first recharge after the first-level lower level joins. If your friend joins through your invitation and recharges 1000 for the first time, you will get 200</p>
                </div>
                <div className="btn-close">
                    <button onClick={closeModal} >CANCEL</button>
                </div>
            </Modal>
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>Promotion</span>
                </div>
                <div className="right">
                    <FontAwesomeIcon icon={faBars} className="bar" />
                </div>
            </nav>
            <div className="pro-cont">
                <div className="container">
                    <div className="headline">
                        Bonous:₹
                        <span>0</span>
                    </div>
                </div>
                <div className="level-box">
                    <div className="level-content">
                        <div className="level-list">
                            <ul className="layout">
                                <li>
                                    <ol className="one-ol">Total People</ol>
                                    <ol className="two-ol">0</ol>
                                </li>
                                <li>
                                    <ol className="one-ol">Contribution</ol>
                                    <ol className="two-ol">₹ 0</ol>
                                </li>
                            </ul>
                            <div className="layout-bot">
                                <div className="bot-list">
                                    <p className="titles">My Promotion Code</p>
                                    <p className="answer">5914CC86</p>
                                </div>
                                <div className="bot-list">
                                    <p className="titles">My Promotion Link</p>
                                    <p className="answer height">https://red-bu11.app/#</p>
                                </div>
                            </div>
                            <div className="open-link">
                                <button onClick={copyToClipboard}>Copy Link</button>
                                {successMessage && <p className="success message success-message">{successMessage}</p>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tabs">
                    <div className="van-tab-wrap">
                        <div className="van-tab-nav">
                            <div className={`van-tab ${activeTab === 0 ? "active-tab-promo" : ""}`}
                                onClick={() => handleTabClick(0)}>
                                <span className="van-tab-new">Level 1（0）</span>
                            </div>
                            <div className={`van-tab ${activeTab === 1 ? "active-tab-promo" : ""}`}
                                onClick={() => handleTabClick(1)}>
                                <span className="van-tab-new">Level 2（0）</span>
                            </div>
                            <div className={`van-tab ${activeTab === 2 ? "active-tab-promo" : ""}`}
                                onClick={() => handleTabClick(2)}>
                                <span className="van-tab-new">Level 3（0）</span>
                            </div>
                        </div>
                    </div>
                    <div className="van-tab--cont">
                        {
                            activeTab === 0 ? <>
                                <div className="van-tab--pane">
                                    <div className="van-search">
                                        <div className="van-search-content">
                                            <div className="van-cell">
                                                <div className="van-field-left-icon">
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </div>
                                                <div className="van-cell-value">
                                                    <div className="van-field-body">
                                                        <input type="search" placeholder="search" className="van-field-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="child-items">
                                        <div className="child-item">ID</div>
                                        <div className="child-item">Phone</div>
                                        <div className="child-item">Water Reward</div>
                                        <div className="child-item">First Reward</div>
                                    </div>
                                    <p>No data available</p>
                                    <div className="pagination">
                                        <ul className="page-box">
                                            <li className="page">
                                                <span>1-10 Of 1</span>
                                            </li>
                                            <li className="page-btn">
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </> : activeTab === 1 ? <>
                                <div className="van-tab--pane">
                                    <div className="van-search">
                                        <div className="van-search-content">
                                            <div className="van-cell">
                                                <div className="van-field-left-icon">
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </div>
                                                <div className="van-cell-value">
                                                    <div className="van-field-body">
                                                        <input type="search" placeholder="search" className="van-field-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="child-items">
                                        <div className="child-item">ID</div>
                                        <div className="child-item">Phone</div>
                                        <div className="child-item">Water Reward</div>
                                        <div className="child-item">First Reward</div>
                                    </div>
                                    <p>No data available</p>
                                    <div className="pagination">
                                        <ul className="page-box">
                                            <li className="page">
                                                <span>1-10 Of 1</span>
                                            </li>
                                            <li className="page-btn">
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </> : activeTab === 2 ? <>
                                <div className="van-tab--pane">
                                    <div className="van-search">
                                        <div className="van-search-content">
                                            <div className="van-cell">
                                                <div className="van-field-left-icon">
                                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                </div>
                                                <div className="van-cell-value">
                                                    <div className="van-field-body">
                                                        <input type="search" placeholder="search" className="van-field-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="child-items">
                                        <div className="child-item">ID</div>
                                        <div className="child-item">Phone</div>
                                        <div className="child-item">Water Reward</div>
                                        <div className="child-item">First Reward</div>
                                    </div>
                                    <p>No data available</p>
                                    <div className="pagination">
                                        <ul className="page-box">
                                            <li className="page">
                                                <span>1-10 Of 1</span>
                                            </li>
                                            <li className="page-btn">
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </> : ""
                        }
                    </div>
                </div>
            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home'  >
                            <FontAwesomeIcon icon={faHouse} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span>Win</span>
                    </li>

                    <li>
                        <Link to='/' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#009688'} />
                        </Link>
                        <span style={{
                            color: '#009688'
                        }}>My</span>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default Promotion;