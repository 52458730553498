import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faHouse, faUser, faMagnifyingGlass, faArrowLeft, faPlus, faCreditCard, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


function Bankcard() {

    const redirectToAddBankcard = () => {
        // Redirect to the "Orders" page
        window.location.href = "/AddBankcard";
    };



    return (
        <div className="bank-card">
            <nav className="top-nav">
                <div className="left">
                    <Link to='/My1'>
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>Bankcard</span>
                </div>
                <div className="right">
                    <FontAwesomeIcon icon={faPlus} className="bar" onClick={redirectToAddBankcard} />
                </div>
            </nav>
            <div className="address-list">
                <ul className="list-ul">
                    <li>
                        <ol className="left-icon">
                            <FontAwesomeIcon icon={faCreditCard} color="gray" />
                        </ol>
                        <ol className="center">
                            <p className="name">Utkarsh k patel</p>
                            <p className="info-text">31004876109</p>
                        </ol>
                        <ol className="right-icon">
                            <FontAwesomeIcon icon={faCircleInfo} color="gray" />
                        </ol>
                    </li>
                </ul>
            </div>
            <div className='footer'>
                <ul className='list'>

                    <li>
                        <Link to='/Home1'  >
                            <FontAwesomeIcon icon={faHouse} color={'#808080'} />
                        </Link>
                        <span style={{ color: '#808080' }}>Home</span>
                    </li>


                    <li>
                        <Link to='/Search1'  >
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={'#808080'} />
                        </Link>
                        <span>Search</span>
                    </li>

                    <li>
                        <Link to='/Win'  >
                            <FontAwesomeIcon icon={faTrophy} color={'#808080'} />
                        </Link>
                        <span>Win</span>
                    </li>

                    <li>
                        <Link to='/My1' className='link' >
                            <FontAwesomeIcon icon={faUser} color={'#009688'} />
                        </Link>
                        <span style={{
                            color: '#009688'
                        }}>My</span>
                    </li>

                </ul>
            </div>
        </div>
    );
};

export default Bankcard;