import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrophy,
    faHouse,
    faUser,
    faMagnifyingGlass,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import img1 from "../Images/1.jpg";
import img2 from "../Images/2.jpg";
import img3 from "../Images/3.jpg";
import img4 from "../Images/4.jpg";
import img5 from "../Images/5.jpg";
import img6 from "../Images/6.jpg";
import img7 from "../Images/7.jpg";
import img8 from "../Images/8.jpg";
import img9 from "../Images/9.jpg";
import img10 from "../Images/10.jpg";
import img11 from "../Images/11.jpg";
import img12 from "../Images/12.jpg";
import img13 from "../Images/13.jpg";
import img14 from "../Images/14.jpg";
import img15 from "../Images/15.jpg";
import img16 from "../Images/16.jpg";
import imgp1 from "../Images/p1.jpg"
import imgp2 from "../Images/p2.jpg"

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Product1() {
    const params = useParams();
    console.log(params);



    const data1 = [
        {
            id: 1,
            description:
                "Joyalukkas 18k (750) Rose Gold and Solitaire Pendant for Girl",
            price: "₹ 38576.00",
            image: img1,
        },
        // {
        //   id: 2,
        //   description:
        //     "Ratnavali Jewels American Diamond Traditional Fashion Jewellery Green Necklace Pendant Set with Earring for Women/Girls RV2916G",
        //   price: "₹ 38576.00",
        //   image: img2,
        // },
        // {
        //   id: 3,
        //   description:
        //     "Swasti Jewels Heart Shape Fashion Jewellery Set Pendant Earrings for Women",
        //   price: "₹ 4559.00",
        //   image: img3,
        // },
        // {
        //   id: 4,
        //   description:
        //     "Om Jewells Rhodium Plated Blue Crystal Jewellery Combo of Designer Drop Pendant Set with Adjustable Bangle Kada and Adjustable Solitaire Finger Ring for Girls and Women CO1000209",
        //   price: "₹ 1599.00",
        //   image: img4,
        // },
        // {
        //   id: 5,
        //   description:
        //     "Sukkhi Gleaming Pearl Gold Plated Wedding Jewellery Kundan Peacock Meenakari Multi-String Necklace Set for Women (2191NGLDPP1560)",
        //   price: "₹ 1745.00",
        //   image: img5,
        // },
        // {
        //   id: 6,
        //   description:
        //     "Ananth Jewels 925 Sterling Silver BIS Hallmarked Heart Bracelet for Women",
        //   price: "₹ 9000.00",
        //   image: img6,
        // },
        // {
        //   id: 7,
        //   description:
        //     "Handicraft Kottage ® 1gm 22Ct Gold Plated Pendant and Chain for Men/Women/Girls",
        //   price: "₹ 999.00",
        //   image: img7,
        // },
        // {
        //   id: 8,
        //   description:
        //     "Mansiyaorange Combo of Two Party One Gram Gold Forming Long Haram and Choker Multi Color Jewellery Necklace/Juelry/jwelry Set Jewellery for Women",
        //   price: "₹ 3199.00",
        //   image: img8,
        // },
        // {
        //   id: 9,
        //   description:
        //     "Young & Forever Fashion Jewellery Elite Rose Gold Plated Geometric Shape Stud Earring Pendant Set for Women Princess Length Delicate Chain Cubic Zirconia Necklace Set for Girls Jewelry",
        //   price: "₹ 4450.00",
        //   image: img9,
        // },
        // {
        //   id: 10,
        //   description:
        //     "Ratnavali Jewels American Diamond Cz Gold Plated Necklace Set Tennis Necklace Single Line Solitaire Set With Chain & Earring For Women",
        //   price: "₹ 4000.00",
        //   image: img10,
        // },
        // {
        //   id: 11,
        //   description:
        //     "chandrika pearls gems & jewellers Sania Mirza Style Without Piercing Clip on Pressing Type Nose Ring for Women & Girls",
        //   price: "₹ 278.00",
        //   image: img11,
        // },
        // {
        //   id: 12,
        //   description:
        //     "Chandrika Pearls Gems & Jewellers Sterling Silver Pendant Earring with Swarovski Crystal for Girls",
        //   price: "₹ 1060.00",
        //   image: img12,
        // },
        // {
        //   id: 13,
        //   description:
        //     "Chandrika Pearls Gems & Jewellers 92.5 Sterling Silver Real Diamond Valentine Crown King Queen Ring for Girls & Women",
        //   price: "₹ 1920.00",
        //   image: img13,
        // },
        // {
        //   id: 14,
        //   description:
        //     "Chandrika Pearls Gems & Jewellers 925 Pure Sterling Silver Letters Initial Pendant with Gold Polish (R)",
        //   price: "₹ 476.00",
        //   image: img14,
        // },
        // {
        //   id: 15,
        //   description:
        //     "Chandrika Pearls Gems & Jewellers Dhanteras Brass Hindu Puja Camphor Burner Lamp Panch Aarti - 5 Face For Puja",
        //   price: "₹ 1880.00",
        //   image: img15,
        // },
        // {
        //   id: 16,
        //   description:
        //     "Jewels Galaxy Designer American Diamond Gold Plated Bangles for Women/Girls",
        //   price: "₹ 1999.00",
        //   image: img16,
        // },
    ];
    return (
        <div className="fullscreen">
            <nav className="top-nav">
                <div className="left">
                    <Link to="/Search1">
                        <FontAwesomeIcon icon={faArrowLeft} className="icon1" />
                    </Link>
                    <span>Product</span>
                </div>
            </nav>

            <div className="swiper-box">
                <div className="van-swipe my-swipe">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        speed={3000}
                        loop
                        modules={[Autoplay]}
                    >
                        <SwiperSlide>
                            <img src={img1} alt="" style={{ width: 1500, height: 500 }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={imgp1} alt="" style={{ width: 1500, height: 500 }} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={imgp2} alt="" style={{ width: 1500, height: 500 }} />
                        </SwiperSlide>
                        ...
                    </Swiper>
                </div>
            </div>

            {data1.map((item, index) => (
                <div className="product-info" key={index}>
                    <p className="Info">{item.description}</p>
                    <p className="Price">{item.price}</p>
                    <div className="goshop_btn">
                        <button className="disabled_btn">Sold Out</button>
                    </div>
                </div>
            ))}

            <div className="table-info">
                <table>
                    <thead>
                        <tr>
                            <th>Product Specifications</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Brand</td>
                            <td>Ananth Jewels</td>
                        </tr>
                        <tr>
                            <td>Collection</td>
                            <td>Ananth Jewels 925 Silver BIS Hallmark Collection</td>
                        </tr>
                        <tr>
                            <td>Stone</td>
                            <td>Jewels</td>
                        </tr>
                        <tr>
                            <td>Resizable?</td>
                            <td>Y</td>
                        </tr>
                        <tr>
                            <td>Material</td>
                            <td>sterling-silver</td>
                        </tr>
                        <tr>
                            <td>Metal</td>
                            <td>Stamp 925-Sterling</td>
                        </tr>
                        <tr>
                            <td>Metal</td>
                            <td>Silver</td>
                        </tr>
                        <tr>
                            <td>Model Number</td>
                            <td>SLB069</td>
                        </tr>
                        <tr>
                            <td>Packaging</td>
                            <td>Comes with a Case for Gifting</td>
                        </tr>
                        <tr>
                            <td>Stone Shape</td>
                            <td>Round</td>
                        </tr>
                        <tr>
                            <td>Stone Colour</td>
                            <td>Clear</td>
                        </tr>
                        <tr>
                            <td>Stone Clarity</td>
                            <td>FL</td>
                        </tr>
                        <tr>
                            <td>Stone Cut</td>
                            <td>deal</td>
                        </tr>
                        <tr>
                            <td>Inscription</td>
                            <td>925</td>
                        </tr>
                        <tr>
                            <td>Stone Creation Method</td>
                            <td>simulated</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="footer">
                <ul className="list">
                    <li>
                        <Link to="/Home1">
                            <FontAwesomeIcon icon={faHouse} color={"#009688"} />
                        </Link>
                        <span style={{ color: "#009688" }}>Home</span>
                    </li>

                    <li>
                        <Link to="/Search1">
                            <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
                        </Link>
                        <span>Search</span>
                    </li>
                    <li>
                        <Link to="/Win">
                            <FontAwesomeIcon icon={faTrophy} color={"#808080"} />
                        </Link>
                        <span>Win</span>
                    </li>
                    <li>
                        <Link to="/My1" className="link">
                            <FontAwesomeIcon icon={faUser} color={"#808080"} />
                        </Link>
                        <span>My</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Product1;
