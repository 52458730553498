import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faMobileScreenButton,
  faKey,
  faGift,
  faMessage,
  faHouse,
  faUser,
  faMagnifyingGlass,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRegister = () => {
    console.log({ number, password });
    const options = {
      method: "POST",
      url: "https://api.wazir99.com/api/register",
      data: { name: name, mobile: number, email: email, password: password },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data.errors[0]);
        // Handle successful login response here
        if (response.data.status === 0) {
          toast(response.data.errors[0]);
        }
        if (response.data.status === 1) {
          navigate("/Home1");
        }

        localStorage.setItem("userid", response.data.data.userid);
      })
      .catch(function (error) {
        console.error(error);
        // Handle login error here
      });
  };

  return (
    <div className="fullscreen">
      <div className="Header">
        <div className="elements">
          <Link to="/Login">
            <FontAwesomeIcon
              icon={faArrowLeft}
              color={"#fafafa"}
              style={{ marginRight: "30px", marginLeft: "15px" }}
            />
          </Link>
          <h5>Register</h5>
        </div>
      </div>
      <div className="form">
        <div className="inputfield">
          <div id="name">
            <FontAwesomeIcon icon={faCircleUser} />
            <input
              value={name}
              onChange={handleNameChange}
              placeholder="Name"
              type="text"
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            />
          </div>
          <div id="number">
            <FontAwesomeIcon icon={faMobileScreenButton} color={"#808080"} />
            <input
              value={number}
              onChange={handleNumberChange}
              placeholder="Mobile Number"
              type="text"
              maxLength={10}
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            />
          </div>
          <div id="email">
            <FontAwesomeIcon icon={faEnvelope} color={"#808080"} />
            <input
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
              type="text"
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            />
          </div>
          <div id="password">
            <FontAwesomeIcon icon={faKey} color={"#808080"} />
            <input
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              type="password"
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            ></input>
          </div>
          <div id="Code">
            <FontAwesomeIcon icon={faGift} color={"#808080"} />
            <input
              placeholder="Recommendation Code"
              type="text"
              style={{ borderColor: "transparent", marginLeft: "1%" }}
            ></input>
          </div>
          <div className="specialbox">
            <div className="inputbox" style={{ width: "70%" }}>
              <FontAwesomeIcon icon={faMessage} color={"#808080"} />
              <input
                placeholder="Verification Code"
                type="text"
                style={{ borderColor: "transparent", marginLeft: "1%" }}
              />
            </div>
            <button className="otpcode">OTP</button>
          </div>

          <div>
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{ color: "#000" }}
              inputProps={{ "aria-label": "checkbox" }}
            />
            <label>
              I agree <span className="green">Privacy Policy</span>
            </label>
          </div>
          <div className="box_btn">
            <button className="regi_btn" onClick={handleRegister}>
              Register
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="footer">
        <ul className="list">
          <li>
            <Link to="/Home">
              <FontAwesomeIcon icon={faHouse} color={"#808080"} />
            </Link>
            <span>Home</span>
          </li>

          <li>
            <Link to="/Search">
              <FontAwesomeIcon icon={faMagnifyingGlass} color={"#808080"} />
            </Link>
            <span>Search</span>
          </li>

          <li>
            <Link to="/Home" className="link">
              <FontAwesomeIcon icon={faUser} color={"#009688"} />
            </Link>
            <span style={{ color: "#009688" }}>My</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Register;
